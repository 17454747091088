<template lang="pug">
    .products
        .container
            app-breadcrumbs(:links="breadcrumbsLinks")
            .wrapper
                h2 Аксессуары HAVAL
                filters-dropdown(
                    v-if="activeCategory"
                    ref="filters"
                    v-model="filters"
                    :active-category="activeCategory"
                    @showFilteredProducts="showFilteredProducts"
                    @showCategoryProducts="showCategoryProducts"
                )
                sort-dropdown(v-model="sort")
                .divider.forDesktop
                favorites-button
                bin-preview
                .collection-tags
                    .collection-tag(v-for="collection in collections")
                        .collection-tag__name {{ collection.name }}
                        closer-icon.collection-tag__closer(@click="dropCollection(collection.id)")

                products-sidebar(@changeCategory="changeCategoryHandler")

                transition
                    .cards(v-if="products.length")
                        app-product-card(
                            v-for="product in products"
                            :key="product.id"
                            :product="product"
                            allow-fast-view
                        )
                        .cards__bottom(v-if="nextPage")
                            app-button(@click="getProducts") Показать ещё
                    .no-matches-products.app-message(v-else-if="pageLoaded")  В данной категории пока что нет ни одного товара
        div
        .disc {{this?.products?.[0]?.product?.category?.disclaimer}}
</template>

<script>
import ProductsSidebar from '@/components/pages/products/Sidebar';
import FiltersDropdown from '@/components/pages/FiltersDropdown';
import SortDropdown from '@/components/pages/SortDropdown';
import FavoritesButton from '@/components/pages/FavoritesButton';
import BinPreview from '@/components/pages/BinPreview';

import closerIcon from '@/assets/images/icons/closer.svg?inline';

import { getProductsByCategory, getFilteredProducts } from '@/js/api/requests/catalog';
import { COLLECTIONS } from '@/js/const';

export default {
    name: 'products',

    components: {
        ProductsSidebar,
        FiltersDropdown,
        SortDropdown,
        FavoritesButton,
        BinPreview,

        closerIcon,
    },

    data: () => ({
        activeCategory: null,
        products: [],

        filters: {
            categoryIds: [],
            colors: [],
            sizes: [],
            minPrice: null,
            maxPrice: null,
        },

        sort: {
            sortBy: 'time', // price, time
            order: 'desc', // asc, desc
        },

        perPage: 6,
        nextPage: 1,

        pageLoaded: false,
        isFilteredProducts: false,
    }),

    computed: {
        breadcrumbsLinks() {
            return [
                {
                    to: { name: 'Home' },
                    name: 'Главная',
                },
                {
                    name: 'Аксессуары HAVAL',
                },
            ];
        },

        collections() {
            const collections = this.$route.query.collections;
            return COLLECTIONS.filter(c => collections?.includes(c.id));
        },

        selectedCollectionsIds() {
            return this.$route.query.collections?.split(',') || [];
        },
    },

    watch: {
        sort: {
            handler: async function() {
                if (this.isFilteredProducts) {
                    this.showFilteredProducts();
                } else {
                    this.showCategoryProducts();
                }
            },
            deep: true,
        },
    },

    methods: {
        async showFilteredProducts() {
            const params = {
                categories: this.activeCategory.isCommon ? this.filters.categoryIds : [this.activeCategory.id],
                tags: this.selectedCollectionsIds,
                colors: this.filters.colors,
                sizes: this.filters.sizes,
                minPrice: this.filters.minPrice,
                maxPrice: this.filters.maxPrice,
                sort: this.sort.sortBy,
                order: this.sort.order,
            };
            const data = await getFilteredProducts(params);
            this.products = data.entries;
            this.nextPage = null;
            this.isFilteredProducts = true;
        },

        async showCategoryProducts() {
            this.products = [];
            this.nextPage = 1;
            this.pageLoaded = false;
            await this.getProducts();
            this.pageLoaded = true;
        },

        async changeCategoryHandler(category) {
            this.activeCategory = category;
            this.showCategoryProducts();
            this.isFilteredProducts = false;
        },

        async getProducts() {
            if (!this.nextPage) return;
            const data = await getProductsByCategory(this.activeCategory.id, this.nextPage, this.perPage, this.sort.sortBy, this.sort.order, this.selectedCollectionsIds);
            this.products = this.products.concat(data.entries);
            this.nextPage = data.metadata.next;
        },

        dropCollection(id) {
            let restCollections = this.selectedCollectionsIds.filter(c => c !== id);
            this.$refs.filters.toggleSelectCollection(id);
            this.$router.replace({ query: {
                ...this.$route.query,
                collections: restCollections.length ? String(restCollections) : undefined,
            }});
            if (this.isFilteredProducts) {
                this.showFilteredProducts();
            } else {
                this.showCategoryProducts();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.products {
    min-height: calc(100vh - #{rem(51px)});
    padding-bottom: rem(90px);
    .disc{
        width: 90%;
        margin: 30px auto;

        font-size: 10px;
        line-height: 12px;
        color: #444;

    }
    .app-breadcrumbs {
        margin-bottom: rem(35px);
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    h2 {
        align-self: center;

        margin-right: auto;

        font-size: rem(24px);
    }

    .divider {
        width: 2px;
        height: rem(36px);
        margin: rem(-42px) rem(16px) 0 rem(16px);

        background: url(../../assets/images/divider-vertical.svg) repeat-y 0 0 / 100% auto;
    }

    .favorites-button, .bin-preview {
        margin-top: rem(-72px);
    }

    .favorites-button {
        margin-right: rem(12px);
    }

    .collection-tags {
        width: 100%;
        margin: rem(44px) 0 rem(16px);
        padding-left: rem(330px);
    }

    .collection-tag {
        display: inline-flex;
        align-items: center;

        margin-right: rem(16px);
        padding: rem(9px);

        border: 1px solid #000;

        &__name {
            padding-top: rem(3px);

            font-size: rem(14px);
            line-height: rem(26px);
            text-transform: uppercase;
        }

        &__closer {
            width: rem(14px);
            margin-left: rem(5px);

            cursor: pointer;

            fill: rgba(#000, 0.5);

            @include hover {
                fill: #000;
            }
        }
    }

    .products-sidebar, .cards,.no-matches-products {
        align-self: flex-start;
    }

    .products-sidebar {
        margin-right: rem(96px);
    }

    .cards {
        display: flex;
        flex-wrap: wrap;

        width: calc(100% - #{rem(234px)} - #{rem(96px)});
        padding-top: rem(6px);

        .app-product-card {
            width: calc((100% - #{rem(36px)} * 2) / 3);
            margin-bottom: rem(70px);

            &:not(:nth-of-type(3n)) {
                margin-right: rem(36px);
            }
        }

        &__bottom {
            display: flex;
            justify-content: center;

            width: 100%;
            margin: rem(17px) 0 0;
        }
    }

    @include tablet {
        .divider {
            margin: 0 rem(24px);
        }

        .favorites-button {
            margin-right: rem(14px);
        }
    }

    @include mobile_or_P {
        min-height: initial;
        padding-bottom: rem(70px);

        .app-breadcrumbs {
            margin-bottom: rem(20px);
        }

        .wrapper {
            justify-content: space-between;

            max-width: 20rem;
            margin: 0 auto;
        }

        .filters-dropdown {
            order: 5;
        }

        .sort-dropdown {
            order: 6;
        }

        .favorites-button, .bin-preview {
            order: -1;

            margin-top: rem(-66px);
        }

        .favorites-button {
            margin-right: rem(7px);
        }

        .collection-tags {
            order: 4;

            margin: 0 0 rem(16px);
            padding-left: 0;
        }

        .collection-tag {
            margin-right: rem(8px);
        }

        .products-sidebar, .cards, .no-matches-products {
            margin-top: rem(36px);
        }

        .products-sidebar {
            margin-right: 0;
        }

        .cards, .no-matches-products {
            order: 7;
        }

        .cards {
            flex-direction: column;

            width: 100%;

            .app-product-card {
                width: 100%;
                margin-bottom: rem(50px);

                &:not(:nth-of-type(3n)) {
                    margin-right: auto;
                }
            }
        }
    }
}
</style>
