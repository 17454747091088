import Vue from 'vue';
import VueRouter from 'vue-router';
import VueRouterBackButton from 'vue-router-back-button';

import Home from '../views/home/index';
import Products from '../views/products/index';
import ProductsDetail from '../views/products/detail/index';
import Order from '../views/order/index';
import Favorites from '../views/favorites/index';
import Page404 from '../views/404/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            layout: 'transparent-header-layout',
        },
    },
    {
        path: '/products/:slug',
        name: 'ProductsDetail',
        component: ProductsDetail,
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
    },
    {
        path: '/order',
        name: 'Order',
        component: Order,
    },
    {
        path: '/favorites',
        name: 'Favorites',
        component: Favorites,
    },

    {
        path: '/404',
        name: '404',
        component: Page404,
    },
    {
        path: '*',
        redirect: '/404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.name !== from.name) {
            if (to.name === 'Products') {
                return savedPosition;
            }
            return { x: 0, y: 0 };
        }
    },
});
Vue.use(VueRouterBackButton, {
    router,
    ignoreRoutesWithSameName: true,
});

export default router;
