export const COLLECTIONS = [
    {
        id: 'haval_dargo',
        name: 'HAVAL DARGO',
        image: require('@/assets/images/home-collections/haval-dargo.jpg'),
    },
    {
        id: 'haval_base',
        name: 'HAVAL BASIC',
        image: require('@/assets/images/home-collections/haval-base.jpg'),
    },
    {
        id: 'lifestyle',
        name: 'HAVAL JOLION',
        image: require('@/assets/images/home-collections/haval-jolion.jpg'),
    },
];
