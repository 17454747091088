<template lang="pug">
    .dealers-map(:class="{ invalid: error }")
        .tabs.forMobile_or_p
            .tab(
                :class="{ active: activeTab === 'map' }"
                @click="activeTab = 'map'"
            ) На карте
            .tab(
                :class="{ active: activeTab === 'dealers' }"
                @click="activeTab = 'dealers'"
            ) Списком

        .dealers(:class="{ open: activeTab === 'dealers'}")
            .find-input
                app-text-field(
                    v-model="findDealerName"
                    placeholder="Найти дилерский центр"
                )
                .clearer(
                    v-if="findDealerName"
                    @click="findDealerName=''"
                )
            .list(ref="dealersList")
                div
                    .item(
                        v-for="(dealer, index) in filteredDealers"
                        :class="{ active: activeDealer && activeDealer.id === dealer.id}"
                        :data-dealer-id="dealer.id"
                        @click="selectDealer(dealer, markers[index])"
                    )
                        .name {{ dealer.marketingName }}
                        .address {{ dealer.address }}
                        a.phone(v-if="dealer.phone" :href="'tel:'+dealer.phone") {{ formatPhone(dealer.phone) }}
                        a.link(:href="dealer.website" target="_blank") {{ dealer.website }}

            .no-matches-found(v-if="!filteredDealers.length") Совпадений не найдено

        .map#map(:class="{ open: activeTab === 'map'}")

        .error(v-if="error") {{ error }}
</template>

<script>

export default {
    name: 'dealers-map',

    props: {
        value: {
            type: null,
            required: true,
        },

        error: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: () => ({
        map: null,
        activeTab: 'map', // map, dealers

        dealersScrollbar: null,

        findDealerName: '',
        markers: [],
    }),

    computed: {
        activeDealer: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        dealersList() {
            return this.$store.getters.dealers;
        },

        filteredDealers() {
            const dealerName = this.findDealerName.toLowerCase();
            return this.dealersList.filter(d => ~d.marketingName?.toLowerCase().indexOf(dealerName) || ~d.city.name?.toLowerCase().indexOf(dealerName));
        },
    },

    async created() {
        await this.$store.dispatch('getDealers');
        window.ymaps.ready(() => {
            this.initMap();
        });
    },

    mounted() {
        this.dealersScrollbar = window.Scrollbar.init(this.$refs.dealersList, { alwaysShowTracks: true });
    },

    methods: {
        initMap() {
            this.map = new window.ymaps.Map('map', {
                center: [55.656407, 37.527373],
                zoom: 3,
                controls: ['fullscreenControl', 'zoomControl'],
                behaviors: window.isMobileOrTablet ? ['multiTouch'] : ['drag'],
            }, {
                minZoom: 2,
                suppressMapOpenBlock: true,
                zoomControlSize: 'small',
                zoomControlPosition: {
                    top: 200,
                    right: 10,
                },
            });

            if (window.isMobileOrTablet) {
                let eventsPane = this.map.panes.get('events');
                let eventsPaneEl = eventsPane.getElement();
                let twoFingersTimerId = null;

                window.ymaps.domEvent.manager.add(eventsPaneEl, 'touchmove', function (event) {
                    if (event.get('touches').length === 1) {
                        eventsPaneEl.innerHTML = 'Чтобы переместить карту проведите <br>по ней двумя пальцами';
                        eventsPaneEl.classList.add('two-fingers-only');
                        eventsPaneEl.style.transition = 'opacity .3s';
                        eventsPaneEl.style.opacity = '1';
                        clearTimeout(twoFingersTimerId);
                    }
                });

                window.ymaps.domEvent.manager.add(eventsPaneEl, 'touchend', function () {
                    eventsPaneEl.style.transition = 'opacity .8s';
                    eventsPaneEl.style.opacity = '0';
                    twoFingersTimerId = setTimeout(() => {
                        eventsPaneEl.classList.remove('two-fingers-only');
                    }, 800);
                });
            }

            let clusterer = new window.ymaps.Clusterer({
                preset: 'islands#invertedVioletClusterIcons',
                clusterIcons: [
                    {
                        href: require('@/assets/images/map/cluster.svg'),
                        size: [46, 46],
                        offset: [-23, -23],
                    },
                ],
            });

            this.dealersList.forEach(dealer => {
                let marker = new window.ymaps.Placemark(
                    [dealer.latitude, dealer.longitude],
                    {},
                    {
                        iconLayout: 'default#image',
                        iconImageHref: require('@/assets/images/map/icon.svg'),
                        iconImageSize: [40, 50],
                        iconImageOffset: [-20, -50],
                    },
                );
                marker.events.add('click', () => {
                    this.selectDealer(dealer, marker);
                });
                this.markers.push(marker);
            });

            clusterer.add(this.markers);
            this.map.geoObjects.add(clusterer);
        },

        selectDealer(dealer, marker) {
            this.markers.forEach(m => {
                m.options.set('iconImageHref', require('@/assets/images/map/icon.svg'));
            });
            marker.options.set('iconImageHref', require('@/assets/images/map/icon-active.svg'));

            this.map.setCenter([dealer.latitude, dealer.longitude], 12);

            const dealerItem = this.$refs.dealersList.querySelector('.item[data-dealer-id="'+dealer.id+'"]');
            this.dealersScrollbar.scrollTo(0, dealerItem.offsetTop, 300);

            this.activeDealer = dealer;
        },

        formatPhone(phone) {
            let match = phone.match(/^(\+\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);
            if (match) {
                return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
            }
            return '';
        },
    },
};
</script>

<style scoped lang="scss">
.dealers-map {
    position: relative;

    display: flex;

    width: 100%;
    margin-bottom: rem(72px);

    box-shadow: 0 0 2px rgba(#000, 0.1);

    &.invalid {
        box-shadow: 0 0 rem(6px) $red;
    }

    .dealers, .map {
        height: rem(471px);
    }

    .dealers {
        position: relative;

        display: flex;
        flex-shrink: 0;
        flex-direction: column;

        width: rem(360px);

        .find-input {
            position: relative;

            &:hover .app-text-field::v-deep input {
                border-color: $black;
            }

            .app-text-field {
                &::v-deep {
                    input {
                        border-color: #bbb;

                        transition: border-color .2s;

                        &::placeholder {
                            color: #aaa;
                        }

                        &:focus {
                            border-color: $black;
                        }
                    }

                    label {
                        display: none;
                    }
                }
            }

            .clearer {
                position: absolute;
                top: 50%;
                right: rem(15px);

                width: rem(20px);
                height: rem(20px);

                background: url(../../../assets/images/icons/closer.svg) no-repeat center center / 100% auto;

                transform: translateY(-50%);
                cursor: pointer;

                @media (hover: hover) {
                    &:hover {
                        transform: translateY(-50%) scale(1.1);
                    }
                }
            }
        }

        .list {
            &::v-deep .scrollbar {
                &-track{
                    right: 0;

                    width: rem(6px);

                    background: none;
                }

                &-thumb{
                    width: inherit;

                    background: rgba(#000, 0.4);
                }
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            padding: rem(16px) rem(20px);

            border-bottom: 1px solid #f2f2f2;

            cursor: pointer;
            transition: background-color .3s;

            .name {
                margin-bottom: rem(16px);

                font-weight: 500;
            }

            .address, .phone, .link {
                font-size: rem(11px);
                line-height: rem(18px);
            }

            .address, .phone {
                margin-bottom: rem(8px);
            }

            .link {
                text-decoration: underline;
            }

            @media (hover: hover) {
                a:hover {
                    background-color: #EEEEEE;
                }
            }

            &.active {
                background-color: #EEEEEE;
            }
        }

        .no-matches-found {
            position: absolute;
            top: 50%;
            left: 50%;

            font-size: rem(20px);
            white-space: nowrap;

            transform: translateX(-50%);
        }
    }

    .map {
        width: calc(#{rem(742px)} - #{rem(360px)});

        .ymap-container {
            height: 100%;
        }

        &::v-deep {
            .two-fingers-only {
                z-index: 10000 !important;

                display: flex;
                justify-content: center;
                align-items: center;

                text-align: center;
                color: #fff;

                background-color: rgba(0, 0, 0, .45);
            }
        }
    }

    .error {
        position: absolute;
        top: calc(100% + #{rem(10px)});
        left: 0;

        font-size: rem(14px);
        color: $red;
    }

    @include tablet {
        .dealers {
            width: rem(300px);
        }

        .map {
            width: calc(#{rem(620px)} - #{rem(300px)});
        }
    }

    @include mobile_or_P {
        flex-direction: column;

        box-shadow: none;

        .tabs {
            display: flex;

            margin-bottom: rem(16px);
            padding: 0 rem(20px);

            border-bottom: 1px solid #ccc;

            .tab {
                position: relative;

                padding: rem(10px) rem(16px);

                font-size: rem(12px);
                font-weight: 500;
                color: #aaa;
                text-transform: uppercase;

                transition: color .3s;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: rem(-3px);
                    left: 0;

                    height: rem(4px);

                    background-color: $black;

                    opacity: 0;
                    transition: opacity .3s;
                }

                &.active {
                    color: $black;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        .dealers, .map {
            height: rem(436px);

            &:not(.open) {
                position: absolute;
                left: -999999px;
            }
        }

        .dealers {
            width: 100%;

            box-shadow: 0 0 2px rgba(#000, 0.1);
        }

        .map {
            width: 100%;
        }
    }
}
</style>
