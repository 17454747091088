import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import order from './order';
import favorites from './favorites';

import { isEmpty } from 'lodash';
import { getMenu, getDealers } from '@/js/api/requests/catalog';

export default new Vuex.Store({
    state: {
        menu: [],
        dealers: [],
    },

    mutations: {
        setMenu: (state, data) => {
            state.menu = data;
        },

        setDealers: (state, data) => {
            state.dealers = data;
        },
    },

    actions: {
        async getMenu({ state, commit }) {
            if (!isEmpty(state.menu)) return;

            try {
                const data = await getMenu();
                commit('setMenu', data.entries);
            } catch (error) {
                console.log(error);
            }
        },

        async getDealers({ state, commit }) {
            if (!isEmpty(state.dealers)) return;

            try {
                const data = await getDealers();
                commit('setDealers', data.entries);
            } catch (error) {
                console.log(error);
            }
        },
    },

    getters: {
        menu: state => state.menu,
        dealers: state => state.dealers,
    },

    modules: {
        order,
        favorites,
    },
});
