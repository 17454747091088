<template lang="pug">
    .default-layout
        layout-header
        slot
        layout-new-footer

</template>

<script>
import LayoutHeader from '@/components/layout/Header';
import LayoutNewFooter from '@/components/layout/NewFooter';

export default {
    name: 'default-layout',

    components: {
        LayoutHeader,
        LayoutNewFooter,
    },
};
</script>
