<template lang="pug">
    .pre-order
        .title Предварительный заказ
        .products
            .product(v-for="item in orderItems")
                .product__preview
                    img(:src="getItemImage(item)")
                .product__info
                    .product__name {{ item.title }}
                    .product__code
                        span Арт.:&nbsp;
                        | {{ item.article }}
                    hr
                    .product-price {{ formattedCurrency(item.price) }}
                        .product-price__currency &#8381

            .bottom
                hr
                span Итого <br>к оплате:
                .bottom-total {{ formattedCurrency(orderPrice) }}
                    .bottom-total__currency &#8381
</template>

<script>
import formattedCurrency from '@/js/utils/formatted-currency';
import getImageUrl from '@/js/utils/get-image-url';

export default {
    name: 'pre-order',

    computed: {
        order() {
            return this.$store.getters['order/order'];
        },

        orderPrice() {
            return this.order?.totalPrice;
        },

        orderItems() {
            const items = this.order?.items.entries.filter(i => i.quantity > 0);
            items?.sort((i1, i2) => {
                return i1.id > i2.id ? 1 : i1.id < i2.id ? -1 : 0;
            });
            return items;
        },
    },

    methods: {
        getItemImage(item) {
            return getImageUrl(item.group.image);
        },

        formattedCurrency,
    },
};
</script>

<style scoped lang="scss">
.pre-order {
    flex-shrink: 0;

    width: rem(370px);
    margin-right: rem(58px);
    padding: rem(25px) rem(40px) rem(47px);

    background: rgba(#F2F2F2, 0.4);

    .title {
        margin-bottom: rem(32px);

        font-weight: 500;
    }

    .product {
        display: flex;

        margin-bottom: rem(30px);

        &__preview {
            flex-shrink: 0;

            width: rem(80px);
            margin-right: rem(14px);

            img {
                width: 100%;
            }
        }

        &__info {
            padding-top: rem(6px);
        }

        &__name {
            margin-bottom: rem(3px);

            font-size: rem(13px);
            line-height: rem(16px);
            font-weight: 500;
        }

        &__code {
            font-size: rem(13px);
            line-height: rem(16px);
            font-weight: 500;
            color: $gray;

            span {
                color: $gray-light;
            }
        }

        hr {
            margin: rem(14px) 0;
        }

        &-price {
            font-size: rem(18px);
            line-height: 1.25;
            font-weight: 500;
            white-space: nowrap;

            &__currency {
                display: inline;

                margin-left: 0.4em;

                font-size: 0.65em;
            }
        }
    }

    .bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        hr {
            margin: rem(13px) 0 rem(27px);
        }

        span {
            margin-right: rem(20px);

            font-size: rem(14px);
            line-height: 1.12;
            color: $gray;
        }

        &-total {
            font-size: rem(26px);
            line-height: 1.25;
            font-weight: 500;

            &__currency {
                display: inline;

                margin-left: 0.4em;

                font-size: 0.7em;
            }
        }
    }

    @include mobile_or_P {
        width: 100vw;
        margin-bottom: rem(26px);
        margin-left: rem(-16px);
        padding: rem(25px) rem(10px) rem(40px) 0;

        .title, .products {
            max-width: 20rem;
            margin-right: auto;
            margin-left: auto;
            padding: 0 rem(10px);
        }
    }
}
</style>
