import { get } from '@/js/api/http-helper';

export function getMenu() {
    return get('/menu');
}

export function getProductsByCategory(id, page=1, perPage=1000, sort='time', order='asc', tags) {
    const params = {
        page,
        per_page_limit: perPage,
        sort,
        order,
        tags,
    };
    return get(`/category/${id}/product/group`, params);
}

export function getProductBySlug(slug) {
    return get(`/product/${slug}`);
}

export function getGroupById(id) {
    return get(`/product/group/${id}`);
}

export function getFilters(categoryIds) {
    return get(`category/filters`, {
        categories: categoryIds,
    });
}

export function getFilteredProducts(params) {
    params.per_page_limit = 1000;
    return get(`/category/product/group`, params);
}

export function getDealers() {
    return get('/dealer');
}

export function getBanners() {
    return get('/banner');
}

export function getCollections() {
    return get('/collection');
}
