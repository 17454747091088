<template lang="pug">
    .app-product-card
        router-link.app-product-card__preview(:to="productLink")
            .app-product-card__image
                img(:src="productImage")
            .app-product-card-tags
                .app-product-card-tags__item(v-if="isProductFeatured") Скоро в продаже
                .app-product-card-tags__item.blue(v-if="isProductSold") Распродано

        router-link.app-product-card__name(:to="productLink") {{ productName }}
        hr

        .app-product-card__center
            .app-product-card__price {{ formattedCurrency(productPrice) }}
                .app-product-card__price-currency &#8381;
            favorites-toggler(
                v-if="allowToggleFavorites"
                :product-id="product.id"
                small
            )

        .app-product-card__bottom
            app-button(
                tag="router-link"
                :to="productLink"
            ) Подробнее

        .app-product-card__fast-view(
            v-if="allowFastView"
            @click="openFastViewPopup"
        )
            eye-icon
            | Быстрый просмотр товара

        fast-view-popup(
            ref="fastViewPopup"
            :product="product"
            :productGroups="productGroups"
        )
</template>

<script>
import FastViewPopup from '@/components/app/app-product-card/FastViewPopup';
import FavoritesToggler from '@/components/pages/FavoritesToggler';

import binIcon from '@/assets/images/icons/bin.svg?inline';
import binChosenIcon from '@/assets/images/icons/bin-chosen.svg?inline';
import eyeIcon from '@/assets/images/icons/eye.svg?inline';

import getImageUrl from '@/js/utils/get-image-url';
import formattedCurrency from '@/js/utils/formatted-currency';
import { getProductBySlug } from '@/js/api/requests/catalog';

export default {
    name: 'app-product-card',

    components: {
        FavoritesToggler,
        FastViewPopup,

        binIcon,
        binChosenIcon,
        eyeIcon,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        allowFastView: {
            type: Boolean,
            required: false,
            default: false,
        },

        allowToggleFavorites: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        productGroups: [],
    }),

    computed: {
        productName() {
            return this.product.product.title;
        },

        productPrice() {
            return this.product.product.price;
        },

        productSlug() {
            return this.product.product.slug;
        },

        productLink() {
            return {
                name: 'ProductsDetail',
                params: { slug: this.product.product.slug },
                query: { group: this.product.color.slug },
            };
        },

        productImage() {
            return getImageUrl(this.product.image);
        },

        isProductFeatured() {
            return this.product.featured;
        },

        isProductSold() {
            return this.product.variants.entries.every(v => v.sold);
        },
    },

    methods: {
        async openFastViewPopup() {
            await this.getProductGroups();
            this.$refs.fastViewPopup.open();
        },

        async getProductGroups() {
            if (this.productGroups.length) return;
            try {
                const { groups } = await getProductBySlug(this.productSlug);
                this.productGroups = groups.entries;
            } catch (e) {
                console.log(e);
            }
        },

        formattedCurrency,
    },
};
</script>
