<template lang="pug">
    .home-intro
        .swiper-container(ref="slider")
            .swiper-wrapper
                .swiper-slide(
                    v-for="banner in banners"
                    :key="banner.id"
                )
                    .background(:style="{ backgroundImage: 'url('+getImageUrl(showMobileBanner ? banner.imageXs : banner.image)+')'}")
                    .container
                        .title(v-html="banner.name")
                        app-button(
                            tag="a"
                            :href="banner.url"
                        ) Перейти в каталог
            .swiper-pagination(ref="pagination")
</template>

<script>
import Swiper from 'swiper';
import getImageUrl from '@/js/utils/get-image-url';
import { getBanners } from '@/js/api/requests/catalog';

export default {
    name: 'home-intro',

    data: () => ({
        banners: [],
    }),

    computed: {
        showMobileBanner() {
            const documentWidth = document.documentElement.offsetWidth;
            return documentWidth < 900 && documentWidth !== 768;
        },
    },

    async mounted() {
        await this.getBanners();
        this.initSlider();
    },

    methods: {
        async getBanners() {
            const data = await getBanners();
            this.banners = data.entries;
        },

        initSlider() {
            new Swiper(this.$refs.slider, {
                watchOverflow: true,
                pagination: {
                    el: this.$refs.pagination,
                    clickable: true,
                },
            });
        },

        getImageUrl,
    },
};
</script>

<style scoped lang="scss">
.home-intro {
    .swiper-container {
        height: 100vh;
    }

    .swiper-slide {
        .background {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background: no-repeat center top / cover;
        }

        .container {
            position: relative;
            z-index: 1;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;

            height: 100%;
            padding: 0 rem(8px) rem(96px);
        }

        .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: rem(12px);

            font-size: rem(26px);
            line-height: rem(41px);
            text-transform: uppercase;

            &::v-deep {
                p {
                    padding: rem(8px) rem(13px);

                    background: #fff;

                    &:not(:first-child) {
                        margin-top: rem(-16px);
                    }
                }

                strong,
                b {
                    font-weight: 500;
                }

                i,
                em {
                    font-style: italic;
                }
            }
        }
    }

    .swiper-pagination {
        bottom: rem(22px);

        display: flex;
        justify-content: center;

        &::v-deep {
            .swiper-pagination-bullet {
                width: rem(55px);
                height: rem(4px);
                margin: 0 rem(8px);

                background: #fff;
                border-radius: 0;

                opacity: 0.5;
                transition: opacity 0.2s;

                &:hover {
                    opacity: 0.7;
                }

                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
        }
    }

    @include mobile_or_P {
        .swiper-container {
            height: rem(430px);
        }

        .swiper-slide {
            .container {
                max-width: 100vmin;
                padding: 0 rem(20px) rem(50px);
            }

            .title {
                margin-bottom: rem(19px);

                font-size: rem(20px);
                line-height: rem(36px);

                &::v-deep {
                    b {
                        margin-top: rem(-8px);
                    }
                }
            }

            .app-button {
                width: auto;
                margin: 0;
            }
        }

        .swiper-pagination {
            &::v-deep {
                .swiper-pagination-bullet {
                    margin: 0 rem(6px);
                }
            }
        }
    }
}
</style>
