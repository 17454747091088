<template lang="pug">
    component.app-button(
        :is="tag"
        :class="classes"
        v-bind="attributes"
        v-on="listeners"
    )
        slot
</template>

<script>
export default {
    name: 'app-button',

    props: {
        tag: {
            type: String,
            default: 'button',
        },

        transparent: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'app-button--transparent': this.transparent,
                'app-button--disabled': this.disabled,
                'app-button--loading': this.loading,
            };
        },

        attributes() {
            return this.$attrs;
        },

        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>
