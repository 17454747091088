<template lang="pug">
    .header-models-submenu
        .tabs(ref="tabs")
            .container
                .tabs__item(
                    v-for="tab in tabs"
                    :class="{ active: tab.id === activeTabId }"
                    @click="changeActiveTab(tab)"
                ) {{ tab.name }}
                .tabs__line

        .cars
            transition(
                mode="out-in"
                @enter="$emit('transition-enter')"
            )
                div(
                    v-for="tab in tabs"
                    v-if="tab.id === activeTabId"
                    :key="tab.id"
                )
                    .block(v-for="category in tab.categories")
                        .container
                            .title {{ category.title }}
                            .text {{ category.text }}
                        .container.container--cars
                            .cars-item(v-for="model in category.models")
                                .cars-item__image
                                    a(:href="model.link" target="_blank")
                                        img(:src="model.image")
                                a.cars-item__name(:href="model.link" target="_blank") {{ model.name }}
                                .cars-item__price {{ model.price }}

        .bottom
            .container
                a(href="https://haval.ru/online-stock/" target="_blank") Автомобили в наличии
</template>

<script>
export default {
    name: 'header-models-submenu',

    data: () => ({
        tabs: [
            {
                id: 0,
                name: 'МОДЕЛЬНЫЙ РЯД',
                categories: {
                    pro: {
                        title: 'PRO',
                        text: 'Мощный двигатель, высокая проходимость и способность преодолевать любое бездорожье.',
                        models: [


                            {
                                link: 'https://haval.ru/models/haval-h3/',
                                image: require('@/assets/images/cars/H3.png'),
                                name: 'HAVAL H3',
                                price: 'от 2 599 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/haval-h9-new/',
                                image: require('@/assets/images/cars/new-h9.jpg'),
                                name: 'НОВЫЙ HAVAL Н9',
                                price: 'от 3 999 000 ₽*',
                            },
                            // {
                            //     image: require('@/assets/images/cars/lock.png'),
                            //     name: 'СКОРО В ПРОДАЖЕ',
                            // },
                        ],
                    },
                    city: {
                        title: 'CITY',
                        text: 'Комфортное передвижение по городу и преимущество современных технологий.',
                        models: [


                            {
                                link: 'https://haval.ru/models/haval-jolion-new2024/',
                                image: require('@/assets/images/cars/jolion.jpg'),
                                name: 'НОВЫЙ HAVAL JOLION',
                                price: 'от 1 999 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/new-haval-m6/',
                                image: require('@/assets/images/cars/M6.png'),
                                name: 'HAVAL M6',
                                price: 'от 1 979 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/new-haval-dargo/#dargox',
                                image: require('@/assets/images/cars/Dargo-x.jpg'),
                                name: 'HAVAL DARGO X',
                                price: 'от 3 069 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/new-haval-dargo/',
                                image: require('@/assets/images/cars/Dargo.png'),
                                name: 'HAVAL DARGO',
                                price: 'от 2 799 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/new-haval-jolion/',
                                image: require('@/assets/images/cars/Jolion.png'),
                                name: 'HAVAL JOLION',
                                price: 'от 1 849 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/haval-f7-new2024',
                                image: require('@/assets/images/cars/F7.png'),
                                name: 'HAVAL F7',
                                price: 'от 2 399 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/new-haval-f7x/',
                                image: require('@/assets/images/cars/F7x.jpg'),
                                name: 'HAVAL F7x',
                                price: 'от 2 449 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/poer-king-kong/',
                                image: require('@/assets/images/cars/GWM Poer Kingkong.jpg'),
                                name: 'GWM POER KINGKONG',
                                price: 'от 2 899 000 ₽*',
                            },
                            {
                                link: 'https://haval.ru/models/poer/',
                                image: require('@/assets/images/cars/GWM POER.jpg'),
                                name: 'GWM POER',
                                price: 'от 3 249 000 ₽*',
                            },
                            /*{
                                link: 'https://haval.ru/models/new-haval-f7/',
                                image: require('@/assets/images/cars/F7 new.png'),
                                name: 'ОБНОВЛЕННЫЙ HAVAL F7',
                                price: 'от 2 749 000 ₽',
                            },
                            {
                                link: 'https://haval.ru/models/new-haval-f7x/',
                                image: require('@/assets/images/cars/F7x new.png'),
                                name: 'ОБНОВЛЕННЫЙ HAVAL F7x',
                                price: 'от 2 819 000 ₽',
                            },*/
                        ],
                    },
                },
            },
            // {
            //     id: 1,
            //     name: 'Пикапы',
            //     models: [
            //         {
            //             link: 'https://haval.ru/models/poer-king-kong/',
            //             image: require('@/assets/images/cars/GWM Poer Kingkong.jpg'),
            //             name: 'GWM POER KINGKONG',
            //             price: 'от 3 199 000 ₽*',
            //         },
            //         {
            //             link: 'https://haval.ru/models/poer/',
            //             image: require('@/assets/images/cars/GWM POER.jpg'),
            //             name: 'GWM POER',
            //             price: 'от 3 449 000 ₽*',
            //         },
            //         /*{
            //             link: 'https://haval.ru/models/wingle7/',
            //             image: require('@/assets/images/cars/GWM Wingle 7.jpg'),
            //             name: 'GWM Wingle 7',
            //             price: 'от 2 999 000 ₽',
            //         },*/
            //     ],
            // },
        ],
        activeTabId: 0,
    }),

    mounted() {
        this.changeActiveTab(this.tabs[0]);
    },

    methods: {
        changeActiveTab(tab) {
            this.activeTabId = tab.id;
            setTimeout(() => {
                const tabsLine = this.$refs.tabs.querySelector('.tabs__line');
                const activeTab = this.$refs.tabs.querySelector('.tabs__item.active');
                const width = activeTab.offsetWidth;
                const left = activeTab.offsetLeft;
                tabsLine.style.cssText = `left: ${left}px; width: ${width}px`;
            });
        },
    },
};
</script>

<style scoped lang="scss">
.header-models-submenu {
    .container {
        max-width: 1700px;
        padding: 0 100px;
    }

    .tabs {
        background: #f5f5f5;

        .container {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 100px;
                bottom: 0;
                left: 100px;

                border-bottom: 1px solid #ddd;
            }
        }

        &__item {
            display: inline-flex;

            padding: 20px 16px;

            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #aaa;
            text-transform: uppercase;

            cursor: pointer;
            transition: color 0.3s;

            user-select: none;

            @include hover {
                color: $black;
            }

            &.active {
                color: $black;
            }
        }

        &__line {
            position: absolute;
            bottom: 0;
            left: 100px;
            z-index: 1;

            width: 272px;
            height: 3px;

            background: $black;

            transition: 0.3s;
        }
    }

    .cars {
        padding-top: 24px;
        padding-bottom: 24px;

        .container {

            &--cars {
                display: flex;
                flex-wrap: wrap;

                padding: 0 88px;
            }
        }

        .title {
            margin: 24px 0;

            font-size: 24px;
            line-height: 36px;
            font-weight: 500;
            color: #000;

        }

        .text {
            max-width: 80%;
            margin-bottom: 14px;

            font-size: 14px;
            line-height: 1.5;
            color: #000;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 25%;
            margin-bottom: 24px;
            padding: 0 12px;

            &__image {
                display: flex;

                width: 100%;
                margin-bottom: 12px;
                padding: 0 15%;
                aspect-ratio: 2.53;

                img {
                    display: block;

                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &__name {
                margin-bottom: 16px;

                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                color: #222;
            }

            &__price {
                font-family: 'Haval', 'Segoe UI', sans-serif;
                font-size: 12px;
                line-height: 18px;
                color: #aaa;
            }
        }
    }

    .bottom {
        padding: 20px 0;

        background: #f5f5f5;

        a {
            display: inline-flex;
            align-items: center;

            font-size: 13px;
            line-height: 18px;
            font-weight: 500;
            color: #222;
            text-transform: uppercase;

            &:after {
                content: '';

                display: block;

                width: 16px;
                height: 16px;
                margin-top: -2px;
                margin-left: 4px;

                background: url(../../assets/images/icons/arrow-right.svg) no-repeat center center /
                    65% auto;
            }
        }
    }

    @include mobile_or_P {
        .container {
            padding: 0;
        }

        .tabs {
            overflow-x: auto;

            background: none;

            .container {
                display: flex;

                &::after {
                    right: 0;
                    left: 0;
                }
            }

            &__item {
                white-space: nowrap;
            }
        }

        .cars {
            .container {
                flex-wrap: wrap;

                padding: 0;
            }

            .title {
            }

            .text {
                max-width: 75%;
            }

            &-item {
                width: 100%;
                padding: 0 16px;

                &__name {
                    margin-bottom: 16px;

                    font-size: 13px;
                    line-height: 17px;
                }
            }
        }

        .bottom {
            padding: 24px 16px;
        }

        @media screen and (width: 768px) {
            .cars {
                &-item {
                    &__name {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
</style>
