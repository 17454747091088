<template lang="pug">
    .city-modal(@click.self="closeModal")
        .city-modal__content
            .city-modal__title Выберите ваш город

            .city-modal-input(:class="{ 'city-modal-input--focus': isFocus, 'city-modal-input--not-empty': searchValue }")
                input(
                    type="text"
                    v-model="searchValue"
                    @focus="isFocus = true"
                    @blur="isFocus = false"
                )
                .city-modal-input__placeholder Найдите ваш город
                .city-modal-input__line
                search-icon.city-modal-input__lupa(v-if="!searchValue")
                .city-modal-input__clear(v-else @click="searchValue = ''")
                    closer-icon

            .city-modal__locate-button(v-if="!isGeolocationCityApplied" @click="getGeolocation") Определить автоматически

            .city-modal__radios
                label.city-modal-radio(
                    v-for="city in filteredCities"
                    :key="city"
                    :class="{ 'city-modal-radio--empty': city === 'empty', 'city-modal-radio--main': mainCities.includes(city), 'city-modal-radio--active': activeCity === city }"
                    @click="activeCity = city"
                )
                    input.city-modal-radio__input
                    .city-modal-radio__inner {{ city }}
            .city-modal__closer(@click="closeModal")
                closer-icon

            button.city-modal__save-button(@click="saveCity") Применить
</template>

<script>
import closerIcon from '@/assets/images/icons/closer.svg?inline';
import searchIcon from '@/assets/images/icons/search.svg?inline';

export default {
    name: 'city-modal',

    components: {
        closerIcon,
        searchIcon,
    },

    data: () => ({
        isFocus: false,

        searchValue: '',
        activeCity: 'Вся Россия',
        isGeolocationCityApplied: false,
        mainCities: [
            'Москва и МО',
            'Санкт-Петербург',
        ],
        cities: [
            'Тольятти',
            'Москва и МО',
            'Санкт-Петербург',
           /* 'empty',*/
            'Альметьевск',
            'Анапа (офис)',
            'Артем',
            'Архангельск',
            'Астрахань',
            'Барнаул',
            'Белгород',
            'Брянск',
            'Великий Новгород',
            'Владимир',
            'Волгоград',
            'Вологда',
            'Воронеж',
            'Екатеринбург',
            'Иваново',
            'Ижевск',
            'Иркутск',
            'Йошкар-Ола',
            'Казань',
            'Калининград',
            'Калуга',
            'Кемерово',
            'Киров',
            'Кострома',
            'Краснодар',
            'Красноярск',
            'Курск',
            'Липецк',
            'Магнитогорск',
            'Миасс',
            'Минеральные Воды',
            'Мурманск',
            'Набережные Челны',
            'Нижневартовск',
            'Нижний Новгород',
            'Нижний Тагил',
            'Новокузнецк',
            'Новороссийск',
            'Новосибирск',
            'Октябрьский',
            'Омск',
            'Орел',
            'Оренбург',
            'Пенза',
            'Пермь',
            'Петрозаводск',
            'Псков',
            'Ростов-на-Дону',
            'Рязань',
            'Самара',
            'Саранск',
            'Саратов',
            'Смоленск',
            'Ставрополь',
            'Старый Оскол',
            'Стерлитамак',
            'Сургут',
            'Сыктывкар',
            'Тамбов',
            'Тверь',
            'Томск',
            'Тула',
            'Тюмень',
            'Ульяновск',
            'Уфа',
            'Хабаровск',
            'Чебоксары',
            'Челябинск',
            'Череповец',
            'Ярославль',
        ],

        cities_coordinates: {
            'Альметьевск': {
                'latitude': 54.9013662,
                'longitude': 52.2970654,
            },
            'Анапа (офис)': {
                'latitude': 54.9013662,
                'longitude': 52.2970654,
            },
            'Артем': {
                'latitude': 43.350053,
                'longitude': 132.1596594,
            },
            'Архангельск': {
                'latitude': 64.5392985,
                'longitude': 40.5170083,
            },
            'Астрахань': {
                'latitude': 46.3655808,
                'longitude': 48.0559979,
            },
            'Барнаул': {
                'latitude': 53.3481145,
                'longitude': 83.7798362,
            },
            'Белгород': {
                'latitude': 50.5977351,
                'longitude': 36.5858236,
            },
            'Брянск': {
                'latitude': 53.2420071,
                'longitude': 34.3652716,
            },
            'Великий Новгород': {
                'latitude': 58.5214003,
                'longitude': 31.2755051,
            },
            'Владимир': {
                'latitude': 56.1281561,
                'longitude': 40.4082995,
            },
            'Волгоград': {
                'latitude': 48.7072005,
                'longitude': 44.5170207,
            },
            'Вологда': {
                'latitude': 59.2484186,
                'longitude': 39.8356461,
            },
            'Воронеж': {
                'latitude': 51.6592378,
                'longitude': 39.1968284,
            },
            'Екатеринбург': {
                'latitude': 56.8386326,
                'longitude': 60.6054887,
            },
            'Иваново': {
                'latitude': 56.9994677,
                'longitude': 40.9728231,
            },
            'Ижевск': {
                'latitude': 56.8527444,
                'longitude': 53.2113961,
            },
            'Иркутск': {
                'latitude': 52.2863513,
                'longitude': 104.280655,
            },
            'Йошкар-Ола': {
                'latitude': 56.6343763,
                'longitude': 47.8998445,
            },
            'Казань': {
                'latitude': 55.7943877,
                'longitude': 49.1115312,
            },
            'Калининград': {
                'latitude': 54.7073218,
                'longitude': 20.5072458,
            },
            'Калуга': {
                'latitude': 54.5060439,
                'longitude': 36.2515933,
            },
            'Кемерово': {
                'latitude': 55.3909721,
                'longitude': 86.0467864,
            },
            'Киров': {
                'latitude': 58.6035313,
                'longitude': 49.6679219,
            },
            'Кострома': {
                'latitude': 57.8029445,
                'longitude': 40.9907282,
            },
            'Краснодар': {
                'latitude': 45.040235,
                'longitude': 38.9760801,
            },
            'Красноярск': {
                'latitude': 56.009466,
                'longitude': 92.8524162,
            },
            'Курск': {
                'latitude': 51.7303391,
                'longitude': 36.1926448,
            },
            'Липецк': {
                'latitude': 52.6103027,
                'longitude': 39.5946266,
            },
            'Магнитогорск': {
                'latitude': 53.4071891,
                'longitude': 58.9791432,
            },
            'Миасс': {
                'latitude': 55.0455774,
                'longitude': 60.1077757,
            },
            'Минеральные Воды': {
                'latitude': 44.2088942,
                'longitude': 43.1383482,
            },
            'Москва и МО': {
                'latitude': 55.7538789,
                'longitude': 37.6203735,
            },
            'Мурманск': {
                'latitude': 69.0076958,
                'longitude': 33.0686019,
            },
            'Набережные Челны': {
                'latitude': 55.7436475,
                'longitude': 52.3958739,
            },
            'Нижневартовск': {
                'latitude': 60.9397379,
                'longitude': 76.5696206,
            },
            'Нижний Новгород': {
                'latitude': 56.3242093,
                'longitude': 44.0053948,
            },
            'Нижний Тагил': {
                'latitude': 57.9101038,
                'longitude': 59.9813242,
            },
            'Новокузнецк': {
                'latitude': 53.7942757,
                'longitude': 87.2144046,
            },
            'Новороссийск': {
                'latitude': 44.7234264,
                'longitude': 37.7687218,
            },
            'Новосибирск': {
                'latitude': 55.0281016,
                'longitude': 82.9210575,
            },
            'Октябрьский': {
                'latitude': 54.4815344,
                'longitude': 53.4656611,
            },
            'Омск': {
                'latitude': 54.9848136,
                'longitude': 73.3674638,
            },
            'Орел': {
                'latitude': 52.9672573,
                'longitude': 36.0696479,
            },
            'Оренбург': {
                'latitude': 51.7875191,
                'longitude': 55.1017379,
            },
            'Пенза': {
                'latitude': 53.1753884,
                'longitude': 45.0347408,
            },
            'Пермь': {
                'latitude': 58.0103211,
                'longitude': 56.2341778,
            },
            'Петрозаводск': {
                'latitude': 61.78909,
                'longitude': 34.3596263,
            },
            'Псков': {
                'latitude': 57.819284,
                'longitude': 28.3318188,
            },
            'Ростов-на-Дону': {
                'latitude': 47.2224364,
                'longitude': 39.7187866,
            },
            'Рязань': {
                'latitude': 54.625457,
                'longitude': 39.7359992,
            },
            'Самара': {
                'latitude': 53.1951657,
                'longitude': 50.1067691,
            },
            'Санкт-Петербург': {
                'latitude': 59.939125,
                'longitude': 30.3158225,
            },
            'Саранск': {
                'latitude': 54.1807601,
                'longitude': 45.1862263,
            },
            'Саратов': {
                'latitude': 51.530376,
                'longitude': 45.9530257,
            },
            'Смоленск': {
                'latitude': 54.7867168,
                'longitude': 31.8153366,
            },
            'Ставрополь': {
                'latitude': 45.0445439,
                'longitude': 41.9690168,
            },
            'Старый Оскол': {
                'latitude': 51.2965991,
                'longitude': 37.8349849,
            },
            'Стерлитамак': {
                'latitude': 53.6302498,
                'longitude': 55.9315707,
            },
            'Сургут': {
                'latitude': 61.2539773,
                'longitude': 73.3961726,
            },
            'Сыктывкар': {
                'latitude': 61.668789,
                'longitude': 50.8356491,
            },
            'Тамбов': {
                'latitude': 52.7213021,
                'longitude': 41.452258,
            },
            'Тверь': {
                'latitude': 56.8585396,
                'longitude': 35.9117898,
            },
            'Тольятти': {
                'latitude': 53.5206438,
                'longitude': 49.389461,
            },
            'Томск': {
                'latitude': 56.4847036,
                'longitude': 84.9481737,
            },
            'Тула': {
                'latitude': 54.192017,
                'longitude': 37.6153885,
            },
            'Тюмень': {
                'latitude': 57.1530824,
                'longitude': 65.5343118,
            },
            'Ульяновск': {
                'latitude': 54.3080674,
                'longitude': 48.3748717,
            },
            'Уфа': {
                'latitude': 54.734853,
                'longitude': 55.9578647,
            },
            'Хабаровск': {
                'latitude': 48.4647991,
                'longitude': 135.0598811,
            },
            'Чебоксары': {
                'latitude': 56.1439378,
                'longitude': 47.2488718,
            },
            'Челябинск': {
                'latitude': 55.1603659,
                'longitude': 61.4007858,
            },
            'Череповец': {
                'latitude': 59.1269214,
                'longitude': 37.9092243,
            },
            'Ярославль': {
                'latitude': 57.6216145,
                'longitude': 39.897878,
            },
        },
    }),

    computed: {
        filteredCities() {
            if (!this.searchValue) return this.cities;

            const regExp = new RegExp(this.searchValue.toLowerCase());

            const cities = this.cities.filter((city) => {
                return (city !== 'empty' && regExp.test(city.toLowerCase())) || city === this.activeCity;
            });

            cities.sort((c1) => c1 === this.activeCity ? -1 : 0);
            return cities;
        },
    },

    async beforeMount() {
        const geolocationCity = this.$cookies.get('geolocation-city');
        if (geolocationCity && this.cities.includes(geolocationCity)) {
            this.activeCity = geolocationCity;
        } else {
            await this.selectNearestGeolocation();
            this.$cookies.set('geolocation-city', this.activeCity);
        }

        this.$emit('select-city', this.activeCity);
        this.isGeolocationCityApplied = !!this.$cookies.get('geolocation-city-applied');
    },

    methods: {
        saveCity() {
            this.$cookies.set('geolocation-city', this.activeCity);
            this.$emit('select-city', this.activeCity);
            this.closeModal();
        },

        closeModal() {
            this.$emit('close');
        },

        async getGeolocation() {
            await this.selectNearestGeolocation();
            this.$cookies.set('geolocation-city', this.activeCity);
            this.$emit('select-city', this.activeCity);
            this.closeModal();
            this.$emit('open-geolocation-tooltip');
        },

        selectNearestGeolocation() {
            return new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition((location) => {
                    const { latitude: geolocationLatitude, longitude: geolocationLongitude } = location.coords;

                    const nearestCity = Object.entries(this.cities_coordinates).reduce((nearestCity, [city, coords]) => {
                        const distance = getDistanceFromLatLonInKm(geolocationLatitude, geolocationLongitude, coords.latitude, coords.longitude);
                        if (!nearestCity.distance || distance < nearestCity.distance) {
                            return { city, distance };
                        }
                        return nearestCity;
                    }, { city: '', distance: 0 });

                    this.activeCity = nearestCity.city;
                    resolve();
                },
                () => {
                    resolve();
                });
            });

            function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
                var R = 6371; // Radius of the earth in km
                var dLat = deg2rad(lat2-lat1);  // deg2rad below
                var dLon = deg2rad(lon2-lon1);
                var a =
                    Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                    Math.sin(dLon/2) * Math.sin(dLon/2)
                ;
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                var d = R * c; // Distance in km
                return d;
            }

            function deg2rad(deg) {
                return deg * (Math.PI/180);
            }
        },

        geolocationCityApply() {
            this.$cookies.set('geolocation-city-applied', 1);
            this.isGeolocationCityApplied = true;
        },
    },
};
</script>

<style scoped lang="scss">
.city-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #000001;

    background: rgba(0, 0, 0, .5);
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .14);

    &__content {
        position: relative;

        width: 650px;
        max-width: calc(100% - 16px);
        max-height: 100%;
        padding: 20px 40px;
        overflow: auto;

        background-color: #fff;

        @include mobile {
            padding: 20px;
        }
    }

    &__title {
        margin-bottom: 44px;

        font-size: 19px;
        line-height: 25px;
        font-weight: 500;

        @include mobile {
            font-size: 16px;
            line-height: 20px;
        }

        @media screen and (max-height: 630px) {
            margin-bottom: 24px;
        }
    }

    &__closer {
        position: absolute;
        top: 9px;
        right: 18px;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 40px;
        height: 40px;

        cursor: pointer;

        @include mobile {
            top: 0;
            right: 0;
        }

        svg {
            width: 22px;
        }
    }

    &__locate-button {
        margin-bottom: 46px;

        font-size: 16px;
        font-weight: 500;
        color: #3c9af0;

        cursor: pointer;

        @media screen and (max-height: 630px) {
            margin-bottom: 30px;
        }
    }

    &__radios {
        max-height: 260px;
        margin: 30px 0;
        padding: 5px 0 5px 5px;
        overflow: auto;

        @include mobile {
            margin-bottom: 32px;
        }

        @media screen and (max-height: 630px) {
            max-height: 180px;
        }

        &::-webkit-scrollbar {
            width: 6px;

            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: #000;
            background-clip: padding-box;
            border-radius: 4px;
        }
    }

    &__save-button {
        display: block;

        margin: 0 auto;
        padding: 8px 32px 6px 24px;

        font-size: 13px;
        line-height: 1.7;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;

        background-color: #000001;

        cursor: pointer;
        transition: .25s ease-out;

        clip-path: polygon(0 0,100% 0,85% 100%,0 100%);

        @include hover {
            background-color: $blue;
        }
    }
}

.city-modal-input {
    $root: &;

    position: relative;

    width: 100%;
    height: 64px;
    margin-bottom: 16px;

    border: 1px solid #ccc;

    transition: border .25s cubic-bezier(.55, .055, .675, .19);

    input {
        width: 100%;
        height: 100%;
        padding: 28px 64px 10px 16px;
    }

    &__placeholder {
        position: absolute;
        top: 50%;
        left: 16px;

        font-size: 14px;
        color: #aaa;

        transform: translateY(-50%);
        transition-timing-function: cubic-bezier(.55, .055, .675, .19);
        transition-duration: .25s;
        transition-property: transform, font-size;

        pointer-events: none;
    }

    &__line {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        height: 3px;

        background-color: #000001;

        transform: scaleY(0);
        transform-origin: bottom;
        transition: transform .25s cubic-bezier(.55, .055, .675, .19);
    }

    &__lupa {
        position: absolute;
        top: 50%;
        right: 23px;

        width: 17px;

        transform: translateY(-50%);
    }

    &__clear {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 64px;

        cursor: pointer;

        svg {
            width: 19px;
        }
    }

    &--focus, &--not-empty {
        #{$root}__placeholder {
            font-size: 12px;

            transform: translate3d(0, -165%, 0);
        }
    }

    &--focus {
        border-color: #000001;

        #{$root}__line {
            transform: scaleY(1);
        }
    }
}

.city-modal-radio {
    $root: &;

    display: inline-flex;

    width: 50%;
    margin-bottom: 13px;

    font-size: 16px;

    cursor: pointer;

    @include mobile {
        font-size: 15px;
    }

    @media screen and (max-width: 575px) {
        width: 100%;
    }

    &__inner {
        position: relative;

        display: flex;
        align-items: center;

        &::before {
            content: '';

            width: 24px;
            height: 24px;
            margin-right: 12px;

            border: 1px solid #ccc;
            border-radius: 50%;

            transition-delay: 0ms, 0ms, .25s;
            transition-timing-function: cubic-bezier(.55, .055, .675, .19);
            transition-duration: .25s;
            transition-property: border-color, border-width, background-color;
        }

        &::after {
            content: '';
            position: absolute;
            top: 6px;
            left: 6px;

            width: 12px;
            height: 12px;

            background-color: #000;
            border-radius: 50%;

            opacity: 0;
            transition: opacity .25s cubic-bezier(.55, .055, .675, .19);
        }
    }

    &__input {
        position: absolute;
        left: -99999px;

        &:focus + #{$root}__inner::before {
            box-shadow: 0 0 6px #000001;
        }
    }

    @include hover {
        #{$root}__inner::before {
            border-color: #000;
        }
    }

    &--main {
        margin-bottom: 24px;

        font-weight: 500;

        @include mobile {
            margin-bottom: 14px;
        }
    }

    &--empty {
        opacity: 0;

        pointer-events: none;

        @include mobile {
            display: none;
        }
    }

    &--active {
        #{$root}__inner {
            &::before {
                border-width: 2px;
                border-color: #000;
            }

            &::after {
                opacity: 1;
            }
        }
    }
}
</style>
