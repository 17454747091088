<template lang="pug">
    .home-new-items
        .container
            h2.center Новинки из последней коллекции
            .row(v-for="collection in collections")
                .row__photo
                    img(:src="getCollectionImage(collection)")
                .row__products
                    .product(v-for="item in collection")
                        router-link.product__preview(:to="getProductLink(item)")
                            img(:src="getProductImage(item)")
                            .product__soon(v-if="checkIsProductFeatured(item)") Скоро в продаже
                        .product__info
                            router-link.product__name(:to="getProductLink(item)") {{ item.title }}
                            .product__code Арт. {{ getProductArticle(item) }}
                            .product__price {{ formattedCurrency(item.price) }}
                                .product__price-currency &#8381;
                            hr
                            app-button(
                                tag="router-link"
                                :to="getProductLink(item)"
                            ) Подробнее
</template>

<script>
import binIcon from '@/assets/images/icons/bin.svg?inline';
import formattedCurrency from '@/js/utils/formatted-currency';
import getImageUrl from '@/js/utils/get-image-url';
import { getCollections } from '@/js/api/requests/catalog';

export default {
    name: 'home-new-items',

    components: {
        binIcon,
    },

    data: () => ({
        collections: [],
    }),

    created() {
        this.getCollections();
    },

    methods: {
        async getCollections() {
            const data = await getCollections();
            this.collections = data.entries.reduce((collections, item) => {
                if (item.collection === 'top') {
                    collections[0].push(item);
                } else {
                    collections[1].push(item);
                }
                return collections;
            }, [ [], [] ]);
        },

        getCollectionImage(collection) {
            return getImageUrl(collection[0]?.collectionImage || collection[1]?.collectionImage);
        },

        getProductImage(item) {
            return getImageUrl(item.groups.entries[0]?.image);
        },

        getProductArticle(item) {
            return item.groups.entries[0]?.variants.entries[0]?.article;
        },

        checkIsProductFeatured(item) {
            return item.groups.entries[0]?.featured;
        },

        getProductLink(item) {
            return {
                name: 'ProductsDetail',
                params: { slug: item.slug },
                query: { group: item.groups.entries[0]?.color.slug },
            };
        },

        formattedCurrency,
    },
};
</script>

<style scoped lang="scss">
.home-new-items {
    h2 {
        margin-bottom: rem(60px);
    }

    .row {
        display: flex;

        margin-bottom: rem(68px);

        &__photo {
            position: relative;

            flex-shrink: 0;

            width: rem(543px);
            margin-right: rem(83px);

            img {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: top;
            }
        }

        &__products {
            width: 100%;
        }

        &:nth-of-type(even) {
            flex-direction: row-reverse;

            .row__photo {
                margin-right: 0;
                margin-left: rem(83px);
            }
        }
    }

    .product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__preview, &__info {
            width: rem(256px);
        }

        &__preview {
            position: relative;

            height: rem(200px);

            img {
                width: 100%;
                height: 100%;

                object-fit: contain;
            }
        }

        &__soon {
            position: absolute;
            bottom: rem(10px);
            left: rem(-10px);

            display: flex;
            align-items: center;

            height: rem(33px);
            padding: 0 rem(18px) 0 rem(13px);

            font-size: rem(14px);
            font-weight: 500;
            text-transform: uppercase;

            background: $yellow;

            clip-path: polygon(0 0, 100% 0, calc(100% - #{rem(10px)}) 100%, 0 100%);
        }

        &__info {
            hr {
                margin: rem(14px) 0 rem(31px);
            }
        }

        &__name {
            font-size: rem(15px);
            line-height: rem(22px);
            font-weight: 500;
            word-break: break-word;
        }

        &__code {
            margin-bottom: rem(11px);

            line-height: rem(18px);
            color: $gray;
        }

        &__price {
            font-size: rem(24px);
            line-height: 1.25;
            font-weight: 500;

            &-currency {
                display: inline;

                margin-left: 0.4em;

                font-size: 0.7em;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: rem(52px);
        }

        &:nth-of-type(even) {
            flex-direction: row-reverse;
        }
    }

    @include mobile_or_tablet {
        .row {
            &__photo {
                width: rem(450px);
                margin-right: rem(50px);
            }

            &:nth-of-type(even) {
                .row__photo {
                    margin-left: rem(50px);
                }
            }
        }
    }

    @include mobile_or_P {
        h2 {
            margin-bottom: rem(40px);
            padding: 0 rem(24px);
        }

        .row {
            flex-direction: column;

            max-width: 20rem;
            margin: 0 auto rem(46px);

            &__photo {
                width: 100%;
                margin-right: 0;
                margin-bottom: rem(48px);

                img {
                    position: static;
                }
            }

            &:nth-of-type(even) {
                flex-direction: column;

                .row__photo {
                    margin-left: 0;
                }
            }
        }

        .product {
            &__preview {
                width: rem(141px);
            }

            &__soon {
                left: rem(-5px);

                font-size: rem(12px);
            }

            &__info {
                width: rem(132px);

                hr {
                    margin: rem(14px) 0 rem(18px);
                }
            }

            &__name {
                margin-bottom: rem(14px);

                font-size: rem(11px);
                line-height: rem(13px);
            }

            &__code {
                margin-bottom: rem(7px);

                font-size: rem(12px);
                line-height: rem(20px);
            }

            &__price {
                font-size: rem(16px);
            }

            .app-button {
                width: auto;
                margin: 0;
            }

            &:not(:last-of-type) {
                margin-bottom: rem(46px);
            }
        }
    }
}
</style>
