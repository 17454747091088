import { getOrder, initOrder, updateOrder } from '@/js/api/requests/order';

export default {
    namespaced: true,

    state: {
        order: null,
    },

    mutations: {
        setOrder: (state, data) => {
            state.order = data;
        },
    },

    actions: {
        async getOrder({ commit }) {
            try {
                const orderId = localStorage.getItem('orderId');
                if (orderId) {
                    const data = await getOrder(orderId);
                    commit('setOrder', data);
                }
            } catch (e) {
                if (e.response.status === 404) {
                    localStorage.removeItem('orderId');
                }
            }
        },

        async initOrder({ commit }) {
            try {
                const data = await initOrder();
                localStorage.setItem('orderId', data.id);
                commit('setOrder', data);
            } catch (e) {
                console.log(e);
            }

        },

        async updateOrder({ state, commit, dispatch }, data) {
            if (!state.order) {
                await dispatch('initOrder');
            }

            try {
                const res = await updateOrder(state.order.id, data);
                commit('setOrder', {
                    ...state.order,
                    ...res,
                });
            } catch (e) {
                console.log(e);
            }
        },

        clearOrder({ commit }) {
            commit('setOrder', null);
            localStorage.removeItem('orderId');
        },
    },

    getters: {
        order: state => state.order,
    },
};
