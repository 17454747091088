import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import { mask } from 'vue-the-mask';
Vue.directive('mask', mask);

import TextareaAutosize from 'vue-textarea-autosize';
Vue.use(TextareaAutosize);

let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';
SwiperCore.use([ Navigation, Pagination, EffectFade ]);

import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

import YmapPlugin from 'vue-yandex-maps';
const settings = {
    apiKey: 'c44bffc4-3bb1-48dc-b5ac-213cd95d1532',
    lang: 'ru_RU',
    coordorder: 'latlong',
    version: '2.1',
};
Vue.use(YmapPlugin, settings);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies, { expire: '180d'});

import AppButton from '@/components/app/app-button';
Vue.component('app-button', AppButton);
import AppTextField from '@/components/app/app-text-field';
Vue.component('app-text-field', AppTextField);
import AppSelect from '@/components/app/app-select';
Vue.component('app-select', AppSelect);
import AppCheckbox from '@/components/app/app-checkbox';
Vue.component('app-checkbox', AppCheckbox);
import AppBreadcrumbs from '@/components/app/app-breadcrumbs';
Vue.component('app-breadcrumbs', AppBreadcrumbs);
import AppProductCard from '@/components/app/app-product-card';
Vue.component('app-product-card', AppProductCard);
import AppCountPicker from '@/components/app/app-count-picker';
Vue.component('app-count-picker', AppCountPicker);
import AppLoader from '@/components/app/app-loader';
Vue.component('app-loader', AppLoader);

import '@/assets/scss/main.scss';

import '@/js/polyfill';

import '@/plugins/disableScroll.js';
import '@/plugins/detectDevice.js';
import '@/plugins/scrollbar.js';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
