<template lang="pug">
  header.header(:class="classes")
    .header__container
      .nav(:class="{ opened: menuIsOpened, openedSubmenu: openedSubmenu }")
        .nav__top
          .nav__closer(@click="closeMenu")
            closer-icon
        .nav__wrapper
          a(
            href="https://haval.ru/online-stock/#/models"
            target="_blank"
          )
            span АВТО В НАЛИЧИИ
          a(
            :class="{ active: openedSubmenu === 'models' }"
            @click="toggleSubmenu('models')"
          )
            span МОДЕЛИ
          a(
            :class="{ active: openedSubmenu === 'purchase' }"
            @click="toggleSubmenu('purchase')"
          )
            span ПОКУПАТЕЛЯМ
          a(
            :class="{ active: openedSubmenu === 'forOwners' }"
            @click="toggleSubmenu('forOwners')"
          )
            span ВЛАДЕЛЬЦАМ
          a(
            :class="{ active: openedSubmenu === 'specialOffers' }"
            @click="toggleSubmenu('specialOffers')"
          )
            span СПЕЦПРЕДЛОЖЕНИЯ
          a(
            href="https://haval.ru/become_dealer/"
            target="_blank"
          )
            span СТАТЬ ДИЛЕРОМ
          a(
            :class="{ active: openedSubmenu === 'about' }"
            @click="toggleSubmenu('about')"
          )
            span О HAVAL

        transition(
          name="submenu"
          @enter="setSubmenuHeight"
          @leave="resetSubmenuHeight"
        )
          .submenu(
            v-if="openedSubmenu"
            ref="submenu"
          )
            transition(
              name="submenu__item"
              :mode="isMobileOrP ? '' : 'out-in'"
              @enter="setSubmenuHeight"
            )
              .submenu__item(v-if="openedSubmenu === 'purchase'" key="purchase")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenu(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    a.submenu__title(@click="toggleSubmenuChild('purchase')") Выбор и покупка
                    .sublinks.forDesktop
                      a.submenu__title(href="https://haval.ru/purchase/choose-and-offer/online-stock-new/" target="_blank") Автомобили в наличии
                      a.submenu__title(href="https://haval.ru/purchase/choose-and-offer/shop/" target="_blank") Аксесcуары HAVAL
                      a.submenu__title(href="https://haval.ru/purchase/special-offer/" target="_blank") Все специальные предложения
                      a.submenu__title(href="https://haval.ru/purchase/catalogues/" target="_blank") Каталоги и прайс-листы
                      a.submenu__title(href="https://haval.ru/purchase/corporate_clients/" target="_blank") Корпоративным клиентам
                      a.submenu__title(href="https://haval.ru/purchase/find-dealer/" target="_blank") Найти дилера
                      a.submenu__title(href="https://haval.ru/purchase/choose-and-offer/test-drive/" target="_blank") Тест-драйв
                  .col
                    a.submenu__title(@click="toggleSubmenuChild('purchase-1')") Кредит и страхование
                    .sublinks.forDesktop
                      a.submenu__title(href="https://haval.ru/purchase/credit/" target="_blank") HAVAL Credit
                      a.submenu__title(href="https://haval.ru/purchase/credit/faq/" target="_blank") Вопросы и ответы
                      a.submenu__title(href="https://haval.ru/purchase/insurance/" target="_blank") Страхование


              .submenu__item(v-if="openedSubmenu === 'credit'" key="credit")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenu('purchase')")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    a(href="https://haval.ru/purchase/credit/haval-smart/" target="_blank") HAVAL SMART
                    a(href="https://haval.ru/purchase/credit/haval-simple/" target="_blank") HAVAL SIMPLE
                    a(href="https://haval.ru/purchase/credit/haval-standard/" target="_blank") HAVAL STANDARD
                    a(href="https://haval.ru/purchase/credit/faq/" target="_blank") Вопросы и ответы

              .submenu__item(v-if="openedSubmenu === 'forOwners'" key="forOwners")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenu(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    a.submenu__title(@click="toggleSubmenuChild('forOwners')") Сервис
                    .sublinks.forDesktop
                      a.submenu__title(href="https://haval.ru/owners/request/" target="_blank") Запись на сервис
                      a.submenu__title(href="https://haval.ru/offers/dyshitsia-legko/" target="_blank") Очистка кондиционера при прохождении ТО
                  .col
                    a.submenu__title(@click="toggleSubmenuChild('forOwners-1')") Поддержка
                    .sublinks.forDesktop
                      a.submenu__title(href="https://haval.ru/owners/guarantee/" target="_blank") Гарантия HAVAL
                      a.submenu__title(href="https://haval.ru/owners/zabota-v-detalyah/" target="_blank") Забота в деталях
                      a.submenu__title(href="https://haval.ru/owners/my-haval/" target="_blank") Мобильное приложение MY HAVAL
                      a.submenu__title(href="https://haval.ru/owners/haval-protection/" target="_blank") Программа «HAVAL Protection +»
                      a.submenu__title(href="https://haval.ru/owners/roadside-assistance/" target="_blank") Программа «Помощь на дороге»
                      a.submenu__title(href="https://haval.ru/owners/regulations-to/" target="_blank") Регламенты технического обслуживания
                      a.submenu__title(href="https://haval.ru/owners/instructions/" target="_blank") Руководства по эксплуатации
                      a.submenu__title(href="https://haval.ru/owners/epts/" target="_blank") Электронный ПТС
                  .col
                    a.submenu__title(@click="toggleSubmenuChild('forOwners-2')") Аксессуары HAVAL
                    .sublinks.forDesktop
                      a.submenu__title(href="https://shop.haval.ru/products?category=Lifestyle&collections=haval_base" target="_blank") Коллекция HAVAL BASIC
              .submenu__item(v-if="openedSubmenu === 'specialOffers'" key="specialOffers")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenu(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    a.submenu__title(href="https://haval.ru/purchase/special-offer/" target="_blank") Все специальные предложения
                  .col
                    a.submenu__title(href="https://haval.ru/purchase/corporate_clients/" target="_blank") Корпоративным клиентам

              .submenu__item(v-if="openedSubmenu === 'about'" key="about")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenu(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container.submenu__container--mobile
                  .col
                    a.submenu__title(href="https://haval.ru/about/brand/" target="_blank") Бренд Haval
                    a.submenu__title(href="https://haval.ru/about/cybersport/" target="_blank") HAVAL и киберспорт
                    a.submenu__title(href="https://haval.ru/about/development/" target="_blank") Производство в России
                    a.submenu__title(href="https://haval.ru/about/media/" target="_blank") Новости
                    a.submenu__title(href="https://haval.ru/about/contacts/" target="_blank") Контакты

                .submenu__container.submenu__container--desktop
                  .col
                    a.submenu__title(href="https://haval.ru/about/brand/" target="_blank") Бренд Haval
                    a.submenu__title(href="https://haval.ru/about/contacts/" target="_blank") Контакты
                  .col
                    a.submenu__title(href="https://haval.ru/about/cybersport/" target="_blank") HAVAL и киберспорт
                  .col
                    a.submenu__title(href="https://haval.ru/about/development/" target="_blank") Производство в России
                  .col
                    a.submenu__title(href="https://haval.ru/about/media/" target="_blank") Новости


              .submenu__item(v-if="openedSubmenu === 'models'" key="models")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenu(null)")
                    arrow-right-icon
                    | Назад
                header-models-submenu(@transition-enter="setSubmenuHeight")

        transition(
          name="submenu"
          @enter="setSubmenuHeight"
          @leave="resetSubmenuHeight"
        )
          .submenu(
            v-if="openedSubmenuChild && isMobileOrP"
            ref="submenu"
          )
            transition(
              name="submenu__item"
              :mode="isMobileOrP ? '' : 'out-in'"
              @enter="setSubmenuHeight"
            )
              .submenu__item(v-if="openedSubmenuChild === 'purchase'" key="purchase")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenuChild(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    .sublinks
                      a.submenu__title(href="https://haval.ru/purchase/choose-and-offer/online-stock-new/" target="_blank") Автомобили в наличии
                      a.submenu__title(href="https://haval.ru/purchase/choose-and-offer/shop/" target="_blank") Аксесcуары HAVAL
                      a.submenu__title(href="https://haval.ru/purchase/special-offer/" target="_blank") Все специальные предложения
                      a.submenu__title(href="https://haval.ru/purchase/catalogues/" target="_blank") Каталоги и прайс-листы
                      a.submenu__title(href="https://haval.ru/purchase/corporate_clients/" target="_blank") Корпоративным клиентам
                      a.submenu__title(href="https://haval.ru/purchase/find-dealer/" target="_blank") Найти дилера
                      a.submenu__title(href="https://haval.ru/purchase/choose-and-offer/test-drive/" target="_blank") Тест-драйв

              .submenu__item(v-if="openedSubmenuChild === 'purchase-1'" key="purchase")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenuChild(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    .sublinks
                      a.submenu__title(href="https://haval.ru/purchase/credit/" target="_blank") HAVAL Credit
                      a.submenu__title(href="https://haval.ru/purchase/credit/faq/" target="_blank") Вопросы и ответы
                      a.submenu__title(href="https://haval.ru/purchase/insurance/" target="_blank") Страхование



              .submenu__item(v-if="openedSubmenuChild === 'forOwners'" key="forOwners")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenuChild(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    .sublinks
                      a.submenu__title(href="https://haval.ru/owners/request/" target="_blank") Запись на сервис
                      a.submenu__title(href="https://haval.ru/offers/dyshitsia-legko/" target="_blank") Очистка кондиционера при прохождении ТО

              .submenu__item(v-if="openedSubmenuChild === 'forOwners-1'" key="forOwners")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenuChild(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    .sublinks
                      a.submenu__title(href="https://haval.ru/owners/guarantee/" target="_blank") Гарантия HAVAL
                      a.submenu__title(href="https://haval.ru/owners/zabota-v-detalyah/" target="_blank") Забота в деталях
                      a.submenu__title(href="https://haval.ru/owners/my-haval/" target="_blank") Мобильное приложение MY HAVAL
                      a.submenu__title(href="https://haval.ru/owners/haval-protection/" target="_blank") Программа «HAVAL Protection +»
                      a.submenu__title(href="https://haval.ru/owners/roadside-assistance/" target="_blank") Программа «Помощь на дороге»
                      a.submenu__title(href="https://haval.ru/owners/regulations-to/" target="_blank") Регламенты технического обслуживания
                      a.submenu__title(href="https://haval.ru/owners/instructions/" target="_blank") Руководства по эксплуатации
                      a.submenu__title(href="https://haval.ru/owners/epts/" target="_blank") Электронный ПТС

              .submenu__item(v-if="openedSubmenuChild === 'forOwners-2'" key="forOwners")
                .submenu__top.forMobile_or_p
                  .submenu__closer(@click="toggleSubmenuChild(null)")
                    arrow-right-icon
                    | Назад
                .submenu__container
                  .col
                    .sublinks
                      a.submenu__title(href="https://shop.haval.ru/products?category=Lifestyle&collections=haval_base" target="_blank") Коллекция HAVAL BASIC

      .location
        a.location__link(href="https://haval.ru/purchase/find-dealer/" target="_blank")
          location-icon.location__icon
          span.forDesktop Найти дилера

      .geolocation
        .geolocation__link(@click="openGeolocationTooltip")
          geolocation-icon.geolocation__icon
          span.forDesktop {{ geolocationCity || 'Вся Россия' }}
        .geolocation-tooltip(v-if="isGeolocationTooltipOpen")
          .geolocation-tooltip__title Вы находитесь здесь — <b>{{ geolocationCity }}?</b>
          .geolocation-tooltip__actions
            button.geolocation-tooltip__button.yes(type="button" @click="applyGeolocationCity") Да
            button.geolocation-tooltip__button.no(type="button" @click="isCityModalOpen = true") Нет
          closer-icon.geolocation-tooltip__closer

      .burger(@click="openMenu")
        span
        span
        span

      transition
        .menu-overlay.forMobile_or_p(
          v-if="menuIsOpened"
          @click.self="closeMenu"
        )
      a.logo(href="https://haval.ru")
        logo


    teleport(to="body")
      transition
        .menu-overlay.forDesktop(
          v-if="openedSubmenu"
          @click.self="toggleSubmenu(null)"
        )

    transition
      city-modal(
        v-show="isCityModalOpen"
        ref="cityModal"
        @select-city="geolocationCity = $event"
        @close="isCityModalOpen = false"
        @open-geolocation-tooltip="openGeolocationTooltip"
      )
</template>

<script>
import Teleport from 'vue2-teleport';
import HeaderModelsSubmenu from '@/components/layout/HeaderModelsSubmenu';
import CityModal from '@/components/layout/CityModal.vue';

import logo from '@/assets/images/logo.svg?inline';
import locationIcon from '@/assets/images/icons/location.svg?inline';
import geolocationIcon from '@/assets/images/icons/geolocation.svg?inline';
import closerIcon from '@/assets/images/icons/closer-cutted.svg?inline';
import arrowRightIcon from '@/assets/images/icons/arrow-right.svg?inline';

export default {
  name: 'layout-header',

  components: {
    CityModal,
    Teleport,
    HeaderModelsSubmenu,
    logo,
    locationIcon,
    geolocationIcon,
    closerIcon,
    arrowRightIcon,
  },

  props: {
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    openedSubmenu: null,
    openedSubmenuChild: null,
    menuIsOpened: false,

    isGeolocationTooltipOpen: false,
    isCityModalOpen: false,
    geolocationCity: null,
    geolocationCityAplied: false,
  }),

  computed: {
    classes() {
      return {
        transparent: this.transparent,
        'opened-submenu': this.openedSubmenu,
      };
    },

    isMobileOrP() {
      return window.isMobileOrP;
    },
  },

  mounted() {
    window.addEventListener('resize', () => {
      if (this.menuIsOpened && !window.isMobileOrP) {
        this.closeMenu();
      }
    });
  },

  methods: {
    toggleSubmenu(id) {
      if (this.openedSubmenu !== id) {
        this.openedSubmenu = id;
      } else {
        this.openedSubmenu = null;
      }
    },

    toggleSubmenuChild(id) {
      if (this.openedSubmenuChild !== id) {
        this.openedSubmenuChild = id;
      } else {
        this.openedSubmenuChild = null;
      }
    },

    openMenu() {
      this.menuIsOpened = true;
      window.disableScroll();
    },

    closeMenu() {
      this.menuIsOpened = false;
      if (this.openedSubmenu) {
        setTimeout(() => {
          this.openedSubmenu = null;
        }, 500);
      }
      window.enableScroll();
    },

    setSubmenuHeight() {
      const item = this.$refs.submenu.querySelector('.submenu__item');

      if (this.$refs.submenu) {
        this.$refs.submenu.style.height = `${item.scrollHeight}px`;
      }
    },

    resetSubmenuHeight() {
      if (this.$refs.submenu) {
        this.$refs.submenu.style.height = `0px`;
      }
    },

    openGeolocationTooltip() {
      if (this.isGeolocationTooltipOpen) return;

      this.isGeolocationTooltipOpen = true;

      setTimeout(() => {
        document.addEventListener(
            'click',
            () => {
              this.isGeolocationTooltipOpen = false;
            },
            {once: true},
        );
      });
    },

    applyGeolocationCity() {
      this.$refs.cityModal.geolocationCityApply();
    },
  },
};
</script>

<style scoped lang="scss">
@include mobile_or_P {
  //.sublinks.forDesktop {
  //  display: block !important;
  //
  //  margin: 20px !important;
  //}
}

.header {
  position: relative;
  z-index: 100;

  width: 100vw;
  height: 51px;

  &__container {
    display: flex;
    align-items: center;

    height: 100%;
    padding: 0 67px 0 50px;

    background: #fff;
    border-bottom: 0px solid rgba(34, 34, 34, 0);

    @include tablet {
      padding: 0 32px;

      color: #000;
    }

    @include mobile_or_P {
      padding: 0 16px;

      color: #000;
    }
  }

  .logo svg,
  .location__icon,
  .geolocation__icon {
    fill: #636b70;
  }

  .logo svg {
    g {
      fill: #636b70;
    }
  }

  .logo {
    flex-shrink: 0;

    width: 130px;
    margin-left: 34px;

    fill: black;

    @include mobile_or_P {
      width: 100px;
      margin-left: auto;
    }

    svg {
      display: block;

      width: 100%;
    }
  }

  .location,
  .geolocation {
    height: 100%;

    &__link {
      display: flex;
      align-items: center;

      height: 100%;

      font-size: 10px;
      line-height: 11px;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;

      cursor: pointer;

      span {
        padding-top: 3px;
      }
    }

    &__icon {
      flex-shrink: 0;

      margin-right: 4px;
    }
  }

  .location {
    margin-right: 12px;
    margin-left: 12px;

    &__icon {
      width: 12px;
    }

    @include mobile_or_P {
      margin-right: 12px;
    }
  }

  .geolocation {
    position: relative;

    &__link {
      @include hover {
        text-decoration: underline;
      }
    }

    &__icon {
      width: 16px;
    }

    @include mobile_or_P {
      margin-right: 10px;
    }
  }

  .geolocation-tooltip {
    position: absolute;
    top: calc(100% + 7px);
    left: calc(50% + 8px);

    width: max-content;
    padding: 25px 28px 20px 20px;

    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

    transform: translateX(-50%);

    @include mobile_or_P {
      right: -46px;
      left: initial;

      max-width: 100vw;

      transform: initial;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;

      width: 10px;
      height: 10px;

      background: #fff;
      border: 1px solid #ddd;
      border-right: none;
      border-bottom: none;

      transform: translate(-50%, -50%) rotate(45deg);

      @include mobile_or_P {
        right: 46px;
        left: initial;
      }
    }

    &__title {
      margin-bottom: 11px;

      font-size: 13.5px;
      line-height: 20px;
      color: #000001;

      b {
        font-weight: 500;
      }
    }

    &__button {
      margin-right: 16px;
      padding: 13px 30px 11px 20px;

      font-size: 13px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;

      cursor: pointer;

      clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);

      &.yes {
        background-color: #000001;
      }

      &.no {
        background-color: #cccccd;
      }

      &:hover {
        background-color: #00cabf;
      }
    }

    &__closer {
      position: absolute;
      top: 11px;
      right: 9px;

      width: 8px;

      cursor: pointer;

      &::v-deep path {
        fill: #666;
      }
    }
  }

  .burger {
    @include mobile_or_P {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 20px;
      height: 20px;
      padding: 4px 2px;

      span {
        width: 100%;
        height: 2px;

        background: $black;
      }
    }
  }

  .nav,
  .submenu {
    @include mobile_or_P {
      a:not([href]),
      .submenu__title:not([href]) {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          right: 4px;

          width: 21px;
          height: 21px;

          background: url(../../assets/images/icons/arrow-right.svg) no-repeat center center / auto 64%;

          transform: translateY(-50%);
        }
      }
    }
  }

  .nav {
    width: 100%;
    height: 100%;

    @include mobile_or_P {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;

      overflow: hidden;

      background: #fff;

      transform: translate3d(-100%, 0, 0);
      opacity: 0;
      transition: transform 0.5s, opacity 0.5s;

      @media screen and (width: 768px) {
        right: calc(100% - 334px);
      }
    }

    &__top {
      display: none;

      @include mobile_or_P {
        display: block;

        padding: 16px 16px 18px;

        @media screen and (width: 768px) {
          padding: 16px 24px 18px;
        }
      }
    }

    &__wrapper {
      display: flex;
      justify-content: flex-start;

      height: 100%;

      @include mobile_or_P {
        display: block;

        height: calc(100% - 56px);
        padding: 0 16px 16px;
        overflow: auto;

        transition: transform 0.5s;

        @media screen and (width: 768px) {
          padding: 0 24px 16px;
        }
      }

      a {
        position: relative;

        display: flex;
        flex-grow: 1;
        align-items: center;

        width: 0;
        max-width: max-content;
        height: 100%;
        margin-left: 20px;
        padding-top: 3px;
        overflow: hidden;

        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;

        cursor: pointer;

        user-select: none;

        @include mobile_or_P {
          width: initial;
          max-width: initial;
          height: auto;
          margin-left: 0;
          padding: 12px 0;

          font-size: 14px;
          line-height: 21px;
          text-transform: uppercase;

          border-bottom: 1px solid #c3c3c3;
        }

        span {
          overflow: hidden;

          text-overflow: ellipsis;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: -5px;
          left: 0;

          height: 3px;

          background: #ffffff;

          transition: 0.3s ease-out;

          @include mobile_or_P {
            display: none;
          }
        }

        @media (hover: hover) {
          &:hover:after {
            bottom: 10px;
          }
        }

        &.active:after {
          bottom: 10px;
        }
      }
    }

    &__closer {
      @include mobile_or_P {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 24px;
        height: 24px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &.opened {
      @include mobile_or_P {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    &.openedSubmenu {
      .nav__wrapper {
        @include mobile_or_P {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }

  .submenu {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: -2;

    height: 0;
    overflow: hidden;

    background: #fff;

    @include mobile_or_P {
      top: 56px;
      bottom: 0;
      z-index: 1000;

      height: initial !important;
    }

    &-enter-active,
    &-leave-active {
      transition: height 0.5s;

      @include mobile_or_P {
        transition: transform 0.5s;
      }

      .submenu__item {
        transition: opacity 0.5s;
      }
    }

    &-enter,
    &-leave-to {
      @include mobile_or_P {
        transform: translate3d(100%, 0, 0);
      }

      .submenu__item {
        opacity: 0;

        @include mobile_or_P {
          opacity: 1;
        }
      }
    }

    &__item {
      @include mobile_or_P {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 16px 16px 6px;
        overflow: auto;

        background-color: #fff;

        @media screen and (width: 768px) {
          padding: 16px 24px 6px;
        }
      }

      &-enter-active,
      &-leave-active {
        transition: opacity 0.4s;
      }

      &-enter,
      &-leave-to {
        opacity: 0;
      }
    }

    &__top {
      @include mobile_or_P {
        padding: 8px 0 20px;

        border-bottom: 1px solid #c3c3c3;
      }
    }

    &__closer {
      @include mobile_or_P {
        display: flex;
        align-items: flex-start;

        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        color: #222;
        text-transform: uppercase;

        svg {
          width: 16px;
          margin: 0 12px 0 4px;

          transform: scaleX(-1);

          @include mobile_or_P {
            fill: #222;
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;

      width: 100%;
      max-width: 1700px;
      margin: 0 auto;
      padding: 24px 88px 28px;

      @include mobile_or_P {
        padding: 0;
      }

      &--desktop {

        @include mobile_or_P {
          display: none;
        }
      }

      &--mobile {
        display: none;

        @include mobile_or_P {
          display: block;
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      width: 25%;
      padding: 0 12px;

      @include mobile_or_P {
        width: 100%;
        padding: 0;
      }

      &--wide {
        width: 33%;

        @include mobile_or_P {
          width: 100%;
        }
      }
    }

    &__title {
      display: block;

      margin-bottom: 24px;

      font-size: 13.5px;
      line-height: 20px;
      font-weight: 500;
      color: #222;
    }

    a {
      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    a,
    &__title {
      @include mobile_or_P {
        width: 100%;
        margin-bottom: 0;
        padding: 12px 0;

        font-family: 'Haval', sans-serif;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;

        border-bottom: 1px solid #c3c3c3;
      }
    }

    .sublinks {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin: -8px 0 24px;

      color: #222222;

      @include mobile_or_P {
        width: 100%;
        margin: 0 0 24px;
      }

      a {
        margin-bottom: 8px;

        font-size: 12px;
        line-height: 17px;
        font-weight: 400;

        &:last-child {
          margin-bottom: 0;
        }

        @include mobile_or_P {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  &.transparent {
    position: absolute;
    top: 0;
    left: 0;

    color: #fff;

    background: transparent;

    .header__container {
      background: transparent;

      .logo svg {
        g {
          fill: #ffffff;
        }
      }
    }

    .burger {
      span {
        @include mobile_or_P {
          background: #fff;
        }
      }
    }

    &:not(.opened-submenu) {
      color: #fff;

      @include mobile_or_P {
        color: $black;
      }

      .header__container {
        background: transparent;
        border-color: rgba(#fff, 0.3);
      }

      .logo svg,
      .location__icon,
      .geolocation__icon {
        fill: #fff;
      }

      .logo svg {
        g {
          fill: #fff;
        }
      }

      .nav__wrapper a:after {
        background: #fff;
      }
    }

    .header__container,
    .logo svg,
    .location__icon,
    .geolocation__icon {
      transition: 0.25s linear;

      fill: #fff !important;

    }
  }

  @include mobile_or_P {
    .submenu {
      &__item {
        &-enter {
          transform: translate3d(100%, 0, 0);
          opacity: 1;

          &-active {
            transition: transform 0.5s;
          }
        }

        &-leave-active {
          transform: translate3d(100%, 0, 0);
          opacity: 1;
          transition: transform 0.5s;
        }
      }
    }

    &.transparent {
      .header__container {
        background: transparent;
        border-color: rgba(#fff, 0.3);
      }

      .logo svg,
      .location__icon,
      .geolocation__icon {
        fill: #fff;
      }
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  background-color: rgba(#000, 0.45);
}
</style>
