<template lang="pug">
    .filters-dropdown(
        ref="filtersDropdown"
        :class="{ opened: isOpen }"
    )
        app-button.filters-button(
            transparent
            @click="togglePopup"
        )
            filters-icon
            span Фильтры

        transition
            .filters-popup(v-show="isOpen")
                .top.forMobile_or_p
                    span Фильтры
                    app-button(@click="closePopup")
                        closer-icon
                .categories
                    .title Цена
                    .price
                        div
                            span От
                            input(
                                ref="minPrice"
                                type="number"
                                @input="debouncedChangeMinPrice($event.target.value)"
                            )
                        div
                            span до
                            input(
                                ref="maxPrice"
                                type="number"
                                @input="debouncedChangeMaxPrice($event.target.value)"
                            )
                    hr
                    .category
                        .category-name.title(@click="toggleTab") Коллекция
                        .category-list
                            .category-list__wrapper
                                app-checkbox(
                                    v-for="collection in collections"
                                    :key="collection.id"
                                    :label="collection.name"
                                    :value="selectedCollectionsIds.includes(collection.id)"
                                    @input="toggleSelectCollection(collection.id)"
                                )
                    hr
                    template(v-if="activeCategory.isCommon")
                        .category
                            .category-name.title(@click="toggleTab") Категория
                            .category-list
                                .category-list__wrapper
                                    template(v-for="item in menu")
                                        app-checkbox(
                                            v-if="!item.children.length"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="state.categoryIds.includes(item.id)"
                                            @input="toggleSelectCategory(item.id)"
                                        )
                                        .subcategory(v-else)
                                            .subcategory-name(@click="toggleSubcategory") {{ item.name }}
                                            .subcategory-list
                                                .subcategory-list__wrapper
                                                    app-checkbox(
                                                        v-for="subcategoryItem in item.children"
                                                        :key="subcategoryItem.id"
                                                        :label="subcategoryItem.name"
                                                        :value="state.categoryIds.includes(subcategoryItem.id)"
                                                        @input="toggleSelectCategory(subcategoryItem.id)"
                                                    )
                        hr
                    .category
                        .category-name.title(@click="toggleTab") Цвета
                        .category-list
                            .category-list__wrapper
                                app-checkbox(
                                    v-for="color in colors"
                                    :key="color.id"
                                    :label="color.name"
                                    :value="state.colors.includes(color.id)"
                                    @input="toggleSelectColor(color.id)"
                                )
                    hr
                    .category
                        .category-name.title(@click="toggleTab") Размер
                        .category-list
                            .category-list__wrapper
                                app-checkbox(
                                    v-for="size in sizes"
                                    :key="size.id"
                                    :label="size.name"
                                    :value="state.sizes.includes(size.id)"
                                    @input="toggleSelectSize(size.id)"
                                )

                    template(v-if="countButtonIsVisible")
                        app-button(
                            v-if="count > 0"
                            @click="showFilteredProducts"
                        ) Показать {{ count }} {{ countWord }}
                        .no-matches-products(v-else-if="count === 0") Товары не найдены
                        .reset-button(@click.stop="resetFilters")
                            img(src="@/assets/images/icons/reset.svg")
                            | Сбросить

</template>

<script>
import filtersIcon from '@/assets/images/icons/filters.svg?inline';
import closerIcon from '@/assets/images/icons/closer.svg?inline';

import togglePopupMixin from '@/mixins/togglePopupMixin';

import { getFilters, getFilteredProducts } from '@/js/api/requests/catalog';
import { debounce } from 'lodash';
import { COLLECTIONS } from '@/js/const';

export default {
    name: 'filters-dropdown',

    components: {
        filtersIcon,
        closerIcon,
    },

    mixins: [togglePopupMixin],

    props: {
        value: {
            type: Object,
            required: true,
        },

        activeCategory: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        colors: [],
        sizes: [],
        minPrice: null,
        maxPrice: null,

        count: null,
        collections: COLLECTIONS,
        selectedCollectionsIds: [],
    }),

    computed: {
        menu() {
            return this.$store.getters.menu.reduce((total, c) => {
                return total.concat(c.children);
            }, []);
        },

        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
                setTimeout(this.getProductsCount);
            },
        },

        countButtonIsVisible() {
            return (
                this.state.categoryIds.length ||
                this.state.colors.length ||
                this.state.sizes.length ||
                this.state.minPrice ||
                this.state.maxPrice ||
                this.selectedCollectionsIds.length
            );
        },

        countWord() {
            const lastNum = this.count % 10;
            const last2Num = this.count % 100;

            if (+last2Num >= 11 && +last2Num <= 19) {
                return 'товаров';
            } else if (lastNum === 1) {
                return 'товар';
            } else if (lastNum >= 2 && lastNum <= 4) {
                return 'товарa';
            } else {
                return 'товаров';
            }
        },

        debouncedChangeMinPrice() {
            return debounce(this.changeMinPrice, 500);
        },

        debouncedChangeMaxPrice() {
            return debounce(this.changeMaxPrice, 500);
        },
    },

    watch: {
        activeCategory() {
            this.getCategoryFilters();
        },

        isOpen(value) {
            if (value) {
                this.$nextTick(this.updateTabsHeight);
            }
        },
    },

    mounted() {
        this.getCategoryFilters();

        this.selectedCollectionsIds = this.$route.query.collections?.split(',') || [];
    },

    methods: {
        async getCategoryFilters() {
            const data = await getFilters(
                this.activeCategory.isCommon ? this.state.categoryIds : [this.activeCategory.id],
            );
            this.colors = data.colors;
            const sizes = data.sizes;
            sizes.sort((s1, s2) => s1.sortOrder - s2.sortOrder);
            this.sizes = sizes;
            this.minPrice = data.minPrice;
            this.maxPrice = data.maxPrice;
            this.state = {
                ...this.state,
                categoryIds: this.activeCategory.isCommon ? this.state.categoryIds : [],
                colors: this.state.colors.filter(id => this.colors.some(c => c.id === id)),
                sizes: this.state.sizes.filter(id => this.sizes.some(s => s.id === id)),
            };
            this.$nextTick(this.updateTabsHeight);
        },

        // Может вызываться вне компонента
        toggleSelectCollection(id) {
            this.selectedCollectionsIds = this.selectedCollectionsIds.includes(id)
                ? this.selectedCollectionsIds.filter(c => c !== id)
                : this.selectedCollectionsIds.concat(id);
            this.$nextTick(this.getCategoryFilters);
        },

        async toggleSelectCategory(id) {
            let categoryIds = [...this.state.categoryIds];
            this.state = {
                ...this.state,
                categoryIds: categoryIds.includes(id)
                    ? categoryIds.filter(c => c !== id)
                    : categoryIds.concat(id),
            };
            this.$nextTick(this.getCategoryFilters);
        },

        toggleSelectColor(id) {
            let colors = [...this.state.colors];
            this.state = {
                ...this.state,
                colors: colors.includes(id) ? colors.filter(c => c !== id) : colors.concat(id),
            };
        },

        toggleSelectSize(id) {
            let sizes = [...this.state.sizes];
            this.state = {
                ...this.state,
                sizes: sizes.includes(id) ? sizes.filter(s => s !== id) : sizes.concat(id),
            };
        },

        changeMinPrice(value) {
            this.state = {
                ...this.state,
                minPrice: value,
            };
        },

        changeMaxPrice(value) {
            this.state = {
                ...this.state,
                maxPrice: value,
            };
        },

        resetFilters() {
            this.state = {
                categoryIds: [],
                colors: [],
                sizes: [],
                minPrice: null,
                maxPrice: null,
            };

            this.selectedCollectionsIds = [];
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    collections: undefined,
                },
            });

            this.$refs.minPrice.value = null;
            this.$refs.maxPrice.value = null;
            this.$nextTick(this.getCategoryFilters);
            this.$emit('showCategoryProducts');
        },

        toggleTab(e) {
            const category = e.target.closest('.category');
            const list = category.querySelector('.category-list');
            const wrapper = category.querySelector('.category-list__wrapper');

            /*const openedCategory = this.$refs.filtersDropdown.querySelector('.category.opened');
            if (openedCategory && openedCategory !== category) {
                const list = openedCategory.querySelector('.category-list');
                const wrapper = openedCategory.querySelector('.category-list__wrapper');

                if (list.style.height === 'auto') {
                    list.style.height = wrapper.scrollHeight + 'px';
                }

                setTimeout(() => {
                    list.style.height = 0;
                });
                openedCategory.classList.remove('opened');
            }*/
            if (category.classList.contains('opened')) {
                list.style.height = 0;
                category.classList.remove('opened');
            } else {
                list.style.height = wrapper.scrollHeight + 'px';
                category.classList.add('opened');
            }
        },

        toggleSubcategory(e) {
            const subcategory = e.target.closest('.subcategory');
            if (subcategory) {
                const list = subcategory.querySelector('.subcategory-list');
                const wrapper = subcategory.querySelector('.subcategory-list__wrapper');
                const categoryList = subcategory.closest('.category-list');

                if (!subcategory.classList.contains('opened')) {
                    list.style.height = wrapper.scrollHeight + 'px';
                    subcategory.classList.add('opened');
                    categoryList.style.height =
                        categoryList.scrollHeight + wrapper.scrollHeight + 'px';
                } else {
                    list.style.height = 0;
                    subcategory.classList.remove('opened');
                    categoryList.style.height =
                        categoryList.scrollHeight - wrapper.scrollHeight + 'px';
                }
            }
        },

        updateTabsHeight() {
            const openedCategories = this.$refs.filtersDropdown.querySelectorAll(
                '.category.opened',
            );
            openedCategories.forEach(openedCategory => {
                const list = openedCategory.querySelector('.category-list');
                const wrapper = openedCategory.querySelector('.category-list__wrapper');
                list.style.height = wrapper.scrollHeight + 'px';
            });
        },

        async getProductsCount() {
            const params = {
                categories: this.activeCategory.isCommon
                    ? this.state.categoryIds
                    : [this.activeCategory.id],
                tags: this.selectedCollectionsIds,
                colors: this.state.colors,
                sizes: this.state.sizes,
                minPrice: this.state.minPrice,
                maxPrice: this.state.maxPrice,
            };
            const data = await getFilteredProducts(params);
            this.count = data.entries.length;
        },

        showFilteredProducts() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    collections: this.selectedCollectionsIds.length
                        ? String(this.selectedCollectionsIds)
                        : undefined,
                },
            });
            this.$emit('showFilteredProducts');
            this.closePopup();
        },

        clickOutsideHandler(e) {
            if (!e.target.closest('.filters-popup')) {
                this.closePopup();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.filters-dropdown {
    position: relative;
    z-index: 1;

    transition: filter 0.3s;

    &.opened {
        filter: drop-shadow(0 0 rem(25px) rgba(0, 0, 0, 0.15));

        @include only_safari {
            filter: none;
        }

        .filters-button {
            color: $gray;

            background: #fff;

            svg {
                fill: $gray;
            }

            @media (hover: hover) {
                &:hover {
                    color: $black;

                    svg {
                        fill: $black;
                    }
                }
            }
        }
    }
}

.filters-button {
    padding-right: rem(12px);
    padding-left: rem(12px);

    font-size: rem(12px);

    border: none;

    clip-path: none;

    @include mobile_or_P {
        font-size: rem(10px);
    }
}

.filters-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;

    width: rem(330px);
    padding: rem(12px) rem(24px) rem(31px) rem(45px);

    background: #fff;

    @include only_safari {
        box-shadow: 0 0 rem(16px) rgba($black, 0.1);
    }

    .title {
        font-size: rem(14px);
    }

    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: rem(17px) 0 rem(32px);

        span {
            margin-right: rem(6px);

            font-size: rem(12px);
            color: $gray;
        }

        input {
            width: rem(88px);
            height: rem(33px);
            padding: 0 rem(8px);

            border: 1px solid #e0e0e0;

            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .category {
        padding-right: 3%;
        padding-bottom: rem(5px);

        &-name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: rem(20px) 0;

            cursor: pointer;
            transition: opacity 0.2s;

            &:after {
                content: '';

                width: rem(10px);
                height: rem(10px);

                background: url(../../assets/images/icons/arrow-bottom-2.svg) no-repeat center
                    center / contain;

                transition: transform 0.1s;
            }

            @media (hover: hover) {
                &:hover {
                    opacity: 0.8;
                }
            }
        }

        &-list {
            height: 0;
            overflow: hidden;

            transition: height 0.4s;

            &__wrapper {
                border-bottom: 1px solid transparent;
            }
        }

        .app-checkbox {
            margin-bottom: rem(18px);

            &::v-deep {
                label {
                    font-size: rem(12px);
                    line-height: rem(17px);
                    color: $black;

                    &:before {
                        width: rem(15px);
                        height: rem(16px);
                        margin-left: 0;

                        background-image: url(../../assets/images/icons/unchecked-black.svg);
                    }

                    span {
                        height: rem(12px);
                    }
                }

                input {
                    &:checked + label {
                        font-weight: 500;

                        &:before {
                            background-image: url(../../assets/images/icons/checked-black.svg);
                        }
                    }
                }
            }
        }

        &.disabled {
            opacity: 0.5;

            pointer-events: none;
        }

        &.opened .category-name:after {
            transform: rotate(180deg);
        }
    }

    .subcategory {
        &-name {
            display: flex;
            align-items: center;

            margin-bottom: rem(18px);

            font-size: rem(14px);

            cursor: pointer;

            &::after {
                content: '';

                width: rem(10px);
                height: rem(10px);
                margin-left: rem(16px);

                background: url(../../assets/images/icons/arrow-bottom-2.svg) no-repeat center
                    center / contain;

                opacity: 0.5;
                transition: transform 0.1s;
            }
        }

        &-list {
            height: 0;
            padding-left: rem(20px);
            overflow: hidden;

            transition: height 0.4s;

            &__wrapper {
                padding-bottom: 1px;
            }
        }

        &.opened {
            .subcategory-name::after {
                transform: rotate(180deg);
            }
        }
    }

    .app-button {
        margin-top: rem(24px);

        font-size: rem(12px);

        @include mobile_or_P {
            font-size: rem(10px);
        }
    }

    .no-matches-products {
        margin-top: rem(8px);

        font-size: rem(14px);
        font-weight: 500;
    }

    .reset-button {
        display: inline-flex;
        align-items: center;

        margin-top: rem(30px);
        padding-left: rem(18px);

        font-size: rem(14px);

        cursor: pointer;
        transition: opacity 0.2s;

        img {
            width: rem(18px);
            margin-top: rem(-3px);
            margin-right: rem(5px);
        }

        @media (hover: hover) {
            &:hover {
                opacity: 0.75;
            }
        }
    }
}

@include mobile_or_tablet {
    .filters-popup {
        overflow: auto;
    }
}

@include mobile_or_P {
    .filters-dropdown {
        z-index: initial;

        &.opened {
            filter: none;
        }
    }

    .filters-popup {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: initial;
        padding: rem(12px) rem(10px);

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: rem(24px);
            padding-left: rem(31px);

            span {
                font-size: rem(20px);
                font-weight: 500;
            }

            .app-button {
                width: auto;
                margin: 0;
                padding: 0 rem(8px);

                clip-path: none;

                svg {
                    margin: 0;

                    transform: translateX(-1px);
                }
            }
        }

        .categories {
            max-width: 20rem;
            margin: 0 auto;
            padding: 0 rem(31px);
        }

        .reset-button {
            display: flex;

            width: rem(120px);
            margin-right: auto;
            margin-left: auto;
            padding: 0;
        }
    }
}
</style>
